import update from 'immutability-helper';

export default (
  state = {
    contactModalToggle: false
  },
  action
) => {
  switch (action.type) {
    case 'HOME_UPDATE_COLUMN':
      state = update(state, {
        [action.payload.column]: {
          $set: action.payload.value
        }
      });
      break;

    default:
  }
  return state;
};
