import posed from 'react-pose';

export const SkillHexagon = posed.div({
  idle: {
    scale: 1,
    transition: {
      scale: { ease: 'linear', duration: 200 },
      default: { ease: 'linear', duration: 200 }
    }
  },
  active: {
    scale: 1.1,
    transition: {
      scale: { ease: 'linear', duration: 200 },
      default: { ease: 'linear', duration: 200 }
    }
  }
});

export const SkillInfoGroup = posed.div({
  groupIdle: {
    opacity: 0,
    x: 60
  },
  groupActive: {
    opacity: 1,
    x: 0,
    transition: {
      x: { ease: 'linear', duration: 400 },
      opacity: { ease: 'linear', duration: 1000 },
      default: { ease: 'linear', duration: 200 }
    },
    delayChildren: 0,
    staggerChildren: 150
  }
});

export const SkillInfoGroupItem = posed.p({
  groupIdle: {
    opacity: 0,
    x: 150
  },
  groupActive: {
    x: 0,
    opacity: 1,
    transition: {
      x: { ease: 'easeInOut', duration: 400 },
      opacity: { ease: 'easeInOut', duration: 600 },
      default: { ease: 'easeInOut', duration: 400 }
    }
  }
});

/* Page Load & Exit */
export const PageBackGround = posed.div({
  unloaded: {
    backgroundColor: '#000000',
    transition: {
      backgroundColor: { ease: 'easeInOut', duration: 1000 }
    }
  },
  loaded: {
    backgroundColor: '#171717',
    delay: 100,
    transition: {
      backgroundColor: { ease: 'easeInOut', duration: 1000 }
    }
  }
});
export const PageContainer = posed.div({
  unloaded: {
    opacity: 0,
    transition: {
      opacity: { ease: 'easeInOut', duration: 400 },
      default: { ease: 'easeInOut', duration: 400 }
    }
  },
  loaded: {
    opacity: 1,
    delay: 700,
    transition: {
      opacity: { ease: 'easeInOut', duration: 1200 },
      default: { ease: 'easeInOut', duration: 1200 }
    }
  }
});
