import React from 'react';

export default () => (
  <div className="footerbg">
    <div className="container workfooter">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <h4>Do you have a project in mind?</h4>
          <p>
            Contact me at
            <span>
              <a href="mailto:scottvance1234@gmail.com">
                {` scottvance1234@gmail.com`}
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
);
