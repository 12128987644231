import React from 'react';
import { connect } from 'react-redux';
import Lightbox from 'react-image-lightbox';

import * as navActions from '../../../components/nav/services/navActions';
import * as caseActions from './services/WorkCaseActions';
import * as poseComponents from './components/poseComponents';

//// Assets
import TrusouqMock from '../../../img/trusouqmock.jpg';

import WorkDesktopNav from './components/workDesktopNav';
import WorkCaseFooter from './components/workCaseFooter';

class TrusouqCase extends React.Component {
  componentDidMount() {
    this.props.updatePageLoad(true, true);
    console.log(this.props.caseStudy);
  }

  componentWillUnmount() {
    this.props.updatePageLoad(false, false);
  }
  render() {
    return (
      <poseComponents.PageBackGround
        pose={this.props.pageLoaded ? 'loaded' : 'unloaded'}
      >
        <poseComponents.PageContainer>
          <WorkDesktopNav {...this.props} />
          <div class="flex-center container">
            <section id="intro">
              <div id="intro-background" class="row">
                {this.props.lightBoxOpen && (
                  <Lightbox
                    mainSrc={
                      this.props.caseStudy.lightBoxes[
                        this.props.caseStudy.activeLightBoxIndex
                      ].images[
                        this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex
                      ]
                    }
                    nextSrc={
                      this.props.caseStudy.lightBoxes[
                        this.props.caseStudy.activeLightBoxIndex
                      ].images[
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      ]
                    }
                    prevSrc={
                      this.props.caseStudy.lightBoxes[
                        this.props.caseStudy.activeLightBoxIndex
                      ].images[
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length -
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      ]
                    }
                    onCloseRequest={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        false,
                        this.props.caseStudy.activeLightBoxIndex,
                        0
                      )
                    }
                    onMovePrevRequest={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        this.props.caseStudy.activeLightBoxIndex,
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length -
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      )
                    }
                    onMoveNextRequest={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        this.props.caseStudy.activeLightBoxIndex,
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length +
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      )
                    }
                  />
                )}
                <div class="col-xl-12-12 col-lg-12 col-md-12 col-sm-12">
                  <h2 class="h2-responsive caseheadersmall">OUR TEAM KNOWS</h2>
                  <br />
                  <h2 class="h2-responsive caseheader">PHONES</h2>
                </div>
                <div class="col-xl-12-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <img
                    class="img-responsive mockimage"
                    src={TrusouqMock}
                    alt="trusouq-mock"
                  />
                </div>
              </div>
            </section>
          </div>
          <main class="main-content">
            <div class="container">
              <div class="row flex-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <div class="hexagon">
                    <p>01</p>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <h2 class="workheader">BABY STEPS</h2>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <p class="workdescriptiontag">BUILDING A BUSINESS</p>
                </div>
                <div class="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <p class="workdescription">
                    The name "TRUSOUQ" is a mix of the word true and the Arabic
                    word for market. This is a project for a small business
                    venture me and some friends are working on presently.
                    Basically, we import unbranded phone accessories, cases,
                    screen protectors, and gadgets from a connection in China
                    and resell it here under our name for profit. Along with
                    this we do screen repair and general phone troubleshooting.
                    I wanted our small business to feel trustworthy and came up
                    with the logo and online presence to make it feel more
                    authentic. The website contains a fully functional Ecommerce
                    system that leverages the WooCommerce plugin for WordPress.
                  </p>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        0
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[0]}
                      alt="trusouq1"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        1
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[1]}
                      alt="trusouq2"
                    />
                  </div>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        2
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[2]}
                      alt="trusouq3"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        3
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[3]}
                      alt="trusouq4"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <div class="hexagon">
                    <p>02</p>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <h2 class="workheader">STAY STYLIN'</h2>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <p class="workdescriptiontag">DESIGN AND PROMOTION</p>
                </div>
                <div class="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <p class="workdescription">
                    We wanted our company to feel down to earth and trustworthy
                    so we chose to go with green and blue as our color scheme.
                    As my first WordPress site, I had a lot of learning to do. I
                    started off using a theme to get started but ended up
                    adjusting all of it while keeping the cool JS functionality
                    of the Shop area. Throughout the site, I've done my best to
                    use our colors through CSS styling and background images for
                    the content. For marketing, using NinjaForms plugin, I've
                    added a form near the bottom of each page where customers
                    can sign up for our newsletter featuring any new offers we
                    have. For support, I added another form to get in contact
                    with us. Lastly, I've added an "about us" page for customers
                    to learn a little bit about who we are.
                  </p>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        1,
                        0
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[1].images[0]}
                      alt="trusouq5"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        1,
                        1
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[1].images[1]}
                      alt="trusouq6"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <div class="hexagon">
                    <p>02</p>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <h2 class="workheader">THE SHOP</h2>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <p class="workdescriptiontag">GET A NEW LOOK</p>
                </div>
                <div class="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <p class="workdescription">
                    The shop is where the magic happens. The theme I'm
                    leveraging, called Shopkeeper, handled this page very well
                    with WooCommerce. In the back-end, we add a product, its
                    price, description, stock, SKU number, dimensions, weight,
                    images of the product, and products to link to it.
                    Additionally you can group products into categories to allow
                    customers to sort by iPhone 7, Case, Screen Protector, etc.
                    Lastly, the site features a wishlist, cart, and payment
                    system for customers to get their new gear.
                  </p>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        2,
                        0
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[2].images[0]}
                      alt="trusouq7"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        2,
                        1
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[2].images[1]}
                      alt="trusouq8"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        2,
                        2
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[2].images[2]}
                      alt="trusouq9"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-8 col-md 12 col-sm-12 flex-center">
                  <a
                    class="projectlink"
                    href="http://trusouqwordpress.s3-website.us-east-2.amazonaws.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    VISIT THIS PROJECT
                  </a>
                </div>
              </div>
            </div>
            <WorkCaseFooter />
          </main>
        </poseComponents.PageContainer>
      </poseComponents.PageBackGround>
    );
  }
}

const mapStateToProps = state => {
  return {
    logo: state.nav.logo,
    pageLoaded: state.nav.pageLoaded,
    caseStudy: state.works.caseStudies.caseStudies[3],
    caseStudyIndex: state.works.caseStudies.caseStudies.findIndex(
      caseStudy => caseStudy.caseName === 'trusouq'
    ),
    lightBoxOpen: state.works.caseStudies.lightBoxOpen
  };
};

export default connect(
  mapStateToProps,
  { ...navActions, ...caseActions }
)(TrusouqCase);
