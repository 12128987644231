import React from 'react';
import { connect } from 'react-redux';
import Lightbox from 'react-image-lightbox';

import * as navActions from '../../../components/nav/services/navActions';
import * as caseActions from './services/WorkCaseActions';
import * as poseComponents from './components/poseComponents';

//// Assets
import HVGPMock from '../../../img/hvgpmock2.jpg';
// Video
import HVGPVideo1 from '../../../video/casehsv5.mp4';
import HVGPVideo2 from '../../../video/casehsv6.mp4';
import HVGPVideo3 from '../../../video/casehsv7.mp4';

import WorkDesktopNav from './components/workDesktopNav';
import WorkCaseFooter from './components/workCaseFooter';

class HVGPCase extends React.Component {
  componentDidMount() {
    this.props.updatePageLoad(true, true);
  }

  componentWillUnmount() {
    this.props.updatePageLoad(false, false);
  }
  render() {
    return (
      <poseComponents.PageBackGround
        pose={this.props.pageLoaded ? 'loaded' : 'unloaded'}
      >
        <poseComponents.PageContainer>
          <WorkDesktopNav {...this.props} />
          <div class="flex-center container">
            <section id="intro">
              <div id="intro-background" class="row">
                {this.props.lightBoxOpen && (
                  <Lightbox
                    mainSrc={
                      this.props.caseStudy.lightBoxes[
                        this.props.caseStudy.activeLightBoxIndex
                      ].images[
                        this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex
                      ]
                    }
                    nextSrc={
                      this.props.caseStudy.lightBoxes[
                        this.props.caseStudy.activeLightBoxIndex
                      ].images[
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      ]
                    }
                    prevSrc={
                      this.props.caseStudy.lightBoxes[
                        this.props.caseStudy.activeLightBoxIndex
                      ].images[
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length -
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      ]
                    }
                    onCloseRequest={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        false,
                        this.props.caseStudy.activeLightBoxIndex,
                        0
                      )
                    }
                    onMovePrevRequest={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        this.props.caseStudy.activeLightBoxIndex,
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length -
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      )
                    }
                    onMoveNextRequest={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        this.props.caseStudy.activeLightBoxIndex,
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length +
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      )
                    }
                  />
                )}
                <div class="col-xl-12-12 col-lg-12 col-md-12 col-sm-12">
                  <h2 class="h2-responsive caseheadersmall">
                    DOCUMENTARY PHOTOGRAPHY ON
                  </h2>
                  <br />
                  <h2 class="h2-responsive caseheader">THE GRID</h2>
                </div>
                <div class="col-xl-12-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <img
                    class="img-responsive mockimage"
                    src={HVGPMock}
                    alt="hsvgrid-hero"
                  />
                </div>
              </div>
            </section>
          </div>
          <main class="main-content">
            <div class="container">
              <div class="row flex-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <div class="hexagon">
                    <p>01</p>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <h2 class="workheader">LOOKING AT OUR CITY</h2>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <p class="workdescriptiontag">THROUGH A NEW PERSPECTIVE</p>
                </div>
                <div class="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <p class="workdescription">
                    The Huntsville Grid Project is a documentary photography
                    site for students at the University of Alabama in
                    Huntsville’s Art Department photography program. Inspired by
                    the Portland Grid Project, the project focuses on
                    documentary photography, where no editorial adjustments are
                    made to a photograph once the shot is taken. The area of the
                    project is divided by five columns and five rows, to create
                    25 grid areas. Students participating in the Documentary
                    Photography course are assigned two grids each within which
                    to photograph. The HGP is bounded to the North by Pratt
                    Avenue, the South by Governor’s Drive, to the West by
                    Memorial Parkway, and to the East by California Street. The
                    project started in 2012, and is an ongoing documentary
                    effort.
                  </p>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        0
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[0]}
                      alt="hvgp1"
                    />
                  </div>
                </div>
                <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        1
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[1]}
                      alt="hvgp2"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        2
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[2]}
                      alt="hvgp3"
                    />
                  </div>
                </div>
                <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        3
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[3]}
                      alt="hvgp4"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <div class="hexagon">
                    <p>02</p>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <h2 class="workheader">DRAWING UP THE GRID</h2>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <p class="workdescriptiontag">DESIGN AND PROMOTION</p>
                </div>
                <div class="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <p class="workdescription">
                    As a UAH student project, I needed to stick to that theme
                    and brand as closely as possible. With the help of some of
                    my graphic design colleagues, we came up with some ideas for
                    the logo and copy on the site. Additionally, I used UAH's
                    colors and linked back to UAH Art Department for promotion.
                    For simplicity, I wanted all the secondary information to
                    live on one page of the site while the other pages hosted
                    the primary content, photographs.
                  </p>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        1,
                        0
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[1].images[0]}
                      alt="hvgp5"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        1,
                        1
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[1].images[1]}
                      alt="hvgp6"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <div class="hexagon">
                    <p>03</p>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <h2 class="workheader">THINK INSIDE THE BOX</h2>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <p class="workdescriptiontag">IMPLEMENTING THE GRID THEME</p>
                </div>
                <div class="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <p class="workdescription">
                    I wanted to find an interesting ways to implement the grid
                    through the site. On the home page, I first came up with an
                    old school ImageMap that shows a different image every time
                    you hover in over a grid to look like a hover effect. Yet,
                    this ended up feeling a bit clunky and I knew their was a
                    better answer. So, I found a tutorial on an interactive SVG
                    map on Codrops and manipulated it into a grid format in
                    illustrator. Next, I lined up the SVG points to a map of
                    downtown Huntsville using viewpoint factors in the JS
                    provided. After this, I made adjustments to opacity and how
                    points were hidden and shown on the grid.
                  </p>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <video class="workvideo" src={HVGPVideo1} autoPlay loop />
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        2,
                        0
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages mobileshow"
                      src={this.props.caseStudy.lightBoxes[2].images[0]}
                      alt="hvgp7"
                    />
                  </div>
                </div>
              </div>
              <p class="workdescriptiontag" style={{ marginTop: '10px' }}>
                HOME PAGE
              </p>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <p class="workdescription bumpdown">
                    On the photo gallery page, I used Flex to make the columns
                    on the 5 rows flex against each other when hovered over.
                    This creates a simple and engaging way for users to navigate
                    this page. When the viewport gets too small, the columns
                    disappear and each image becomes a row with a set height.
                  </p>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <video class="workvideo" src={HVGPVideo2} autoPlay loop />
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        2,
                        1
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages mobileshow"
                      src={this.props.caseStudy.lightBoxes[2].images[1]}
                      alt="hvgp8"
                    />
                  </div>
                </div>
              </div>
              <p class="workdescriptiontag" style={{ marginTop: '10px' }}>
                PHOTO GALLERY PAGE
              </p>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <p class="workdescription bumpdown">
                    Lastly, on the individual grid page, I tweaked a demo I
                    found on Codrops for a Masonry grid gallery with overlay
                    effects. I ran into a usability problem with the grid as it
                    would construct itself only after all the images were
                    loaded. After tweaking the Masonry grid and using the
                    ImagesLoaded library , I was able to make the grid adjust
                    itself after each image is loaded which allows the users to
                    start viewing images instantly. When viewing an image, EXIF
                    data is extracted from uploaded image and shown to user
                    using the EXIF.js library. The data included for these
                    images includes: camera model, focal length, aperture size,
                    exposure time, and the date taken.
                  </p>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <video class="workvideo" src={HVGPVideo3} autoPlay loop />
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        2,
                        2
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages mobileshow"
                      src={this.props.caseStudy.lightBoxes[2].images[2]}
                      alt="hvgp9"
                    />
                  </div>
                </div>
              </div>
              <p
                class="workdescriptiontag"
                style={{
                  marginTop: '10px',
                  paddingBottom: '0px',
                  marginBottom: '0px'
                }}
              >
                INDIVIDUAL GRID PAGE
              </p>
              <div class="row flex-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <div class="hexagon">
                    <p>04</p>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <h2 class="workheader">AN ONGOING EFFORT</h2>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <p class="workdescriptiontag">NEW PERSPECTIVES AND FACES</p>
                </div>
                <div class="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <p class="workdescription">
                    With new students joining this project, the Huntsville Grid
                    Project needed a way for users to upload their photos
                    looking at our ever-changing city. By using CouchCMS, a
                    simple and open-source CMS, I was able to create a back-end
                    system to allow teachers and students to make an account,
                    login, and upload their new photos to their respective
                    grids. Users upload new images to the grid by creating a new
                    "page" through a php template which has an HTML embed inside
                    for each respective grid. By installing a new addon for
                    Couch, I was also able to add some user control to the
                    admin-access panel which allows only the super admin to
                    change settings and certain elements tagged with PHP that
                    they may want to edit in the future.
                  </p>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        3,
                        0
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[3].images[0]}
                      alt="hvgp10"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        3,
                        1
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[3].images[1]}
                      alt="hvgp11"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-8 col-md 12 col-sm-12 flex-center">
                  <a
                    class="projectlink"
                    href="http://hsvgridproject.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    VISIT THIS PROJECT
                  </a>
                </div>
              </div>
            </div>
            <WorkCaseFooter />
          </main>
        </poseComponents.PageContainer>
      </poseComponents.PageBackGround>
    );
  }
}

const mapStateToProps = state => {
  return {
    logo: state.nav.logo,
    pageLoaded: state.nav.pageLoaded,
    caseStudy: state.works.caseStudies.caseStudies[2],
    caseStudyIndex: state.works.caseStudies.caseStudies.findIndex(
      caseStudy => caseStudy.caseName === 'hvgp'
    ),
    lightBoxOpen: state.works.caseStudies.lightBoxOpen
  };
};

export default connect(
  mapStateToProps,
  { ...navActions, ...caseActions }
)(HVGPCase);
