import React from 'react';

class MobileSkillGroup extends React.Component {
  render() {
    return (
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mobileSkills mobileSkillsContainer">
        <div>
          {this.props.mobileSkills.map(
            (skill, skillIndex) =>
              skill.proficiency === 'Expert' ? (
                <div className="skillgroup" key={skillIndex}>
                  <p className="paragraph-4 skills">{skill.name}</p>
                  <div className="skilltag experttag">
                    <p className="paragraph-4 skilltag">{skill.proficiency}</p>
                  </div>
                </div>
              ) : skill.proficiency === 'Great' ? (
                <div className="skillgroup" key={skillIndex}>
                  <p className="paragraph-4 skills">{skill.name}</p>
                  <div className="skilltag experttag greattag">
                    <p className="paragraph-4 skilltag">{skill.proficiency}</p>
                  </div>
                </div>
              ) : skill.proficiency === 'Good' ? (
                <div className="skillgroup" key={skillIndex}>
                  <p className="paragraph-4 skills">{skill.name}</p>
                  <div className="skilltag experttag goodtag">
                    <p className="paragraph-4 skilltag">{skill.proficiency}</p>
                  </div>
                </div>
              ) : skill.proficiency === 'Novice' ? (
                <div className="skillgroup" key={skillIndex}>
                  <p className="paragraph-4 skills">{skill.name}</p>
                  <div className="skilltag experttag novicetag">
                    <p className="paragraph-4 skilltag">{skill.proficiency}</p>
                  </div>
                </div>
              ) : null
          )}
        </div>
      </div>
    );
  }
}

export default MobileSkillGroup;
