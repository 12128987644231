import React from "react";
import { connect } from "react-redux";
import posed from "react-pose";

const ArrowPrev = posed.div({
  workNavIdle: {
    opacity: 0,
    x: -150,
    y: "-50%",
    transition: { ease: "easeInOut", duration: 1000 },
  },
  workNavActive: {
    opacity: 1,
    x: 0,
    y: "-50%",
    delay: 800,
    transition: { ease: "easeInOut", duration: 1000 },
  },
});

const ArrowNext = posed.div({
  workNavIdle: {
    opacity: 0,
    x: 150,
    y: "-50%",
    transition: { ease: "easeInOut", duration: 1000 },
  },
  workNavActive: {
    opacity: 1,
    x: 0,
    y: "-50%",
    delay: 800,
    transition: { ease: "easeInOut", duration: 1000 },
  },
});

class WorkDesktopNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      next: {
        nextPath: null,
        nextText1: null,
        nextText2: null,
      },
      prev: {
        prevPath: null,
        prevText1: null,
        prevText2: null,
      },
    };

    this.handleNavConfig = this.handleNavConfig.bind(this);
  }

  componentDidMount() {
    this.handleNavConfig(this.props.history.location.pathname);
  }

  handleNavConfig(pathname) {
    switch (pathname) {
      case "/works/grimdawn":
        this.setState({
          next: {
            nextPath: "/works/hvgp",
            nextText1: "HSV Grid",
            nextText2: "Project",
          },
          prev: {
            prevPath: "/works/trusouq",
            prevText1: "Trusouq",
            prevText2: "WordPress",
          },
        });
        break;

      case "/works/gofergift":
        this.setState({
          next: {
            nextPath: "/works/hvgp",
            nextText1: "HSV Grid",
            nextText2: "Project",
          },
          prev: {
            prevPath: "/works/grimdawn",
            prevText1: "Grim",
            prevText2: "Dawn",
          },
        });
        break;

      case "/works/hvgp":
        this.setState({
          next: {
            nextPath: "/works/trusouq",
            nextText1: "Trusouq",
            nextText2: "WordPress",
          },
          prev: {
            prevPath: "/works/grimdawn",
            prevText1: "Grim",
            prevText2: "Dawn",
          },
        });
        break;

      case "/works/trusouq":
        this.setState({
          next: {
            nextPath: "/works/grimdawn",
            nextText1: "Grim",
            nextText2: "Dawn",
          },
          prev: {
            prevPath: "/works/hvgp",
            prevText1: "HSV Grid",
            prevText2: "Project",
          },
        });
        break;

      case "/works/gd-ux":
        this.setState({
          next: {
            nextPath: "/works/grimdawn",
            nextText1: "Grim",
            nextText2: "Dawn",
          },
          prev: {
            prevPath: "/works/trusouq",
            prevText1: "Trusouq",
            prevText2: "WordPress",
          },
        });
        break;

      default:
    }
  }

  render() {
    return (
      <div className="work-navigation">
        <div className="nav-fillpath">
          <ArrowPrev
            className="prev"
            pose={
              this.props.pageLoaded && !this.props.lightBoxOpen
                ? "workNavActive"
                : "workNavIdle"
            }
            onClick={() => {
              this.props.changeRoute(
                this.props.history,
                this.state.prev.prevPath
              );
            }}
            style={{ cursor: "pointer" }}
          >
            <span className="icon-wrap" />
            <h3>
              <strong>{this.state.prev.prevText1}</strong>
              <br />
              {this.state.prev.prevText2}
            </h3>
          </ArrowPrev>
          <ArrowNext
            className="next"
            pose={
              this.props.pageLoaded && !this.props.lightBoxOpen
                ? "workNavActive"
                : "workNavIdle"
            }
            onClick={() => {
              this.props.changeRoute(
                this.props.history,
                this.state.next.nextPath
              );
            }}
            style={{ cursor: "pointer" }}
          >
            <span className="icon-wrap" />
            <h3>
              <strong>{this.state.next.nextText1}</strong>
              <br />
              {this.state.next.nextText2}
            </h3>
          </ArrowNext>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageLoaded: state.nav.pageLoaded,
    lightBoxOpen: state.works.caseStudies.lightBoxOpen,
  };
};

export default connect(mapStateToProps, null)(WorkDesktopNav);
