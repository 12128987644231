import update from "immutability-helper";

/* BG Images */
import grimDawnBG from "../../../img/workBG_grimDawn.jpg";
import goferGiftBG from "../../../img/workBG_goferGift.png";
import hsvGridBG from "../../../img/workBG_hsvGrid.jpg";
import truSouqBG from "../../../img/workBG_trusouq.jpg";
// import graphicDesignBG from "../../../img/workBG_graphicDesign.jpg";
import blankImg from "../../../img/blankImg.jpg";
import distortionImage from "../../../img/displacement/7.jpg";

export default (
  state = {
    pageLoaed: false,
    logo: false,
    activeWorkIndex: 0,
    canvas: {
      width: null,
      height: null,
    },
    distortionImage: distortionImage,
    works: [
      {
        title: "Grim Dawn",
        subtitle: "Expansion Promotion Site",
        backgroundImg: grimDawnBG,
        workRoute: "works/grimdawn",
      },
      // {
      //   title: "GoferGift",
      //   subtitle: "SaaS WordPress Blog",
      //   backgroundImg: goferGiftBG,
      //   workRoute: "works/gofergift",
      // },
      {
        title: "HVGP",
        subtitle: "Community Photography Project Site",
        backgroundImg: hsvGridBG,
        workRoute: "works/hvgp",
      },
      {
        title: "TruSouq",
        subtitle: "WordPress Ecommerce Store",
        backgroundImg: truSouqBG,
        workRoute: "works/trusouq",
      },
      // {
      //   title: 'Design',
      //   subtitle: 'Graphic Design, UI/UX',
      //   backgroundImg: graphicDesignBG,
      //   workRoute: 'works/gd-ux'
      // }
    ],
    blankImg: {
      backgroundImg: blankImg,
    },
  },
  action
) => {
  switch (action.type) {
    case "UPDATE_PAGE_AND_LOGO":
      state = update(state, {
        pageLoaded: {
          $set: action.payload.loaded,
        },
        logo: {
          $set: action.payload.logo,
        },
      });
      break;

    case "UPDATE_ACTIVE_INDEX":
      state = update(state, {
        activeWorkIndex: {
          $set: action.payload,
        },
      });
      break;

    case "WORK_CANVAS_RESIZE":
      state = update(state, {
        canvas: {
          width: {
            $set: action.payload.width,
          },
          height: {
            $set: action.payload.height,
          },
        },
      });
      break;

    default:
  }
  return state;
};
