import React from 'react';
import { connect } from 'react-redux';
import Lightbox from 'react-image-lightbox';

import * as navActions from '../../../components/nav/services/navActions';
import * as caseActions from './services/WorkCaseActions';
import * as poseComponents from './components/poseComponents';

//// Assets
import GDUXMock from '../../../img/gdmock.jpg';
import GDUXVideo1 from '../../../video/figma_wine_app.mp4';
import GDUXVideo2 from '../../../video/figma_logo_pose.mp4';

import WorkDesktopNav from './components/workDesktopNav';
import WorkCaseFooter from './components/workCaseFooter';

class GduxCase extends React.Component {
  componentDidMount() {
    this.props.updatePageLoad(true, true);
  }

  componentWillUnmount() {
    this.props.updatePageLoad(false, false);
  }
  render() {
    return (
      <poseComponents.PageBackGround
        pose={this.props.pageLoaded ? 'loaded' : 'unloaded'}
      >
        <poseComponents.PageContainer>
          <WorkDesktopNav {...this.props} />
          <div class="flex-center container">
            <section id="intro">
              <div id="intro-background" class="row">
                {this.props.lightBoxOpen && (
                  <Lightbox
                    mainSrc={
                      this.props.caseStudy.lightBoxes[
                        this.props.caseStudy.activeLightBoxIndex
                      ].images[
                        this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex
                      ]
                    }
                    nextSrc={
                      this.props.caseStudy.lightBoxes[
                        this.props.caseStudy.activeLightBoxIndex
                      ].images[
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      ]
                    }
                    prevSrc={
                      this.props.caseStudy.lightBoxes[
                        this.props.caseStudy.activeLightBoxIndex
                      ].images[
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length -
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      ]
                    }
                    onCloseRequest={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        false,
                        this.props.caseStudy.activeLightBoxIndex,
                        0
                      )
                    }
                    onMovePrevRequest={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        this.props.caseStudy.activeLightBoxIndex,
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length -
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      )
                    }
                    onMoveNextRequest={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        this.props.caseStudy.activeLightBoxIndex,
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length +
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      )
                    }
                  />
                )}
                <div id="intro-background" class="row">
                  <div class="col-xl-12-12 col-lg-12 col-md-12 col-sm-12">
                    <h2 class="h2-responsive caseheadersmall">ADVENTURES IN</h2>
                    <br />
                    <h2 class="h2-responsive caseheader">CREATIVITY</h2>
                  </div>
                  <div class="col-xl-12-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                    <img
                      class="img-responsive mockimage"
                      src={GDUXMock}
                      alt="gdmock"
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
          <main class="main-content">
            <div class="container">
              <div class="row flex-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <div class="hexagon">
                    <p>01</p>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <h2 class="workheader">Wine App Prototype</h2>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <p class="workdescriptiontag">Figma, React-Pose</p>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                  <div>
                    <video
                      class="workvideo"
                      src={GDUXVideo1}
                      autoPlay
                      loop
                      style={{ height: '667px' }}
                    />
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        this.props.caseStudyLightBox(
                          this.props.caseStudyIndex,
                          true,
                          3,
                          0
                        )
                      }
                    >
                      <img
                        class="img-responsive workimages mobileshow"
                        src={this.props.caseStudy.lightBoxes[3].images[0]}
                        alt="hvgp9"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                  <div>
                    <video
                      class="workvideo"
                      src={GDUXVideo2}
                      autoPlay
                      loop
                      style={{ height: '667px' }}
                    />
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        this.props.caseStudyLightBox(
                          this.props.caseStudyIndex,
                          true,
                          3,
                          1
                        )
                      }
                    >
                      <img
                        class="img-responsive workimages mobileshow"
                        src={this.props.caseStudy.lightBoxes[3].images[1]}
                        alt="hvgp9"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <div class="hexagon">
                    <p>01</p>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <h2 class="workheader">Visual Identity</h2>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <p class="workdescriptiontag">Logos</p>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        0
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[0]}
                      alt="gdux1"
                    />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        1
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[1]}
                      alt="gdux2"
                    />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        2
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[2]}
                      alt="gdux3"
                    />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        3
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[3]}
                      alt="gdux4"
                    />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        4
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[4]}
                      alt="gdux5"
                    />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        5
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[5]}
                      alt="gdux6"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <div class="hexagon">
                    <p>02</p>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <h2 class="workheader">Marketing Materials</h2>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <p class="workdescriptiontag">
                    Brochures, Flyers, Posters, Catalogs
                  </p>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        1,
                        0
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[1].images[0]}
                      alt="gdux7"
                    />
                  </div>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        1,
                        1
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[1].images[1]}
                      alt="gdux8"
                    />
                  </div>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        1,
                        2
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[1].images[2]}
                      alt="gdux9"
                    />
                  </div>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        1,
                        3
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[1].images[3]}
                      alt="gdux10"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <div class="hexagon">
                    <p>04</p>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <h2 class="workheader">Digital Manipulation</h2>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <p class="workdescriptiontag">
                    Photoshop, Mandebulb3D, Apophysis
                  </p>
                </div>
                <div class="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center" />
              </div>
              <div class="row flex-center">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        2,
                        0
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[2].images[0]}
                      alt="gdux11"
                    />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        2,
                        1
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[2].images[1]}
                      alt="gdux12"
                    />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        2,
                        2
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[2].images[2]}
                      alt="gdux13"
                    />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        2,
                        3
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[2].images[3]}
                      alt="gdux13"
                    />
                  </div>
                </div>
              </div>
            </div>
            <WorkCaseFooter />
          </main>
        </poseComponents.PageContainer>
      </poseComponents.PageBackGround>
    );
  }
}

const mapStateToProps = state => {
  return {
    logo: state.nav.logo,
    pageLoaded: state.nav.pageLoaded,
    caseStudy: state.works.caseStudies.caseStudies[4],
    caseStudyIndex: state.works.caseStudies.caseStudies.findIndex(
      caseStudy => caseStudy.caseName === 'gd-ux'
    ),
    lightBoxOpen: state.works.caseStudies.lightBoxOpen
  };
};

export default connect(
  mapStateToProps,
  { ...navActions, ...caseActions }
)(GduxCase);
