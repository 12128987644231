import React from 'react';
import { connect } from 'react-redux';

import * as actions from './services/WorkActions';
import * as navActions from '../../components/nav/services/navActions';

import throttle from '../../services/throttle';

import * as poseComponents from './components/poseComponents';

class Works extends React.Component {
	constructor(props) {
		super(props);
		this.handleScroll = throttle(this.handleScroll.bind(this), 1000);
		this.handleNav = throttle(this.handleNav.bind(this), 1000);
	}

	componentDidMount() {
		this.props.updatePageLoad(true, true);
		setTimeout(() => {
			this.props.workTransitions(this.props.works);
		}, 300);
		this.props.setCanvasSize(
			document.documentElement.clientWidth,
			document.documentElement.clientHeight
		);
	}

	componentWillUnmount() {
		this.props.updatePageLoad(false, false);
	}

	handleNav(direction) {
		let activeIndex = this.props.activeWorkIndex;
		let nextWorkIndex;
		let workLength = this.props.works.length;

		/// Handle Down
		if (direction === 'down') {
			// Check if active is index 0
			if (activeIndex === 0) {
				nextWorkIndex = workLength - 1;
			} else {
				nextWorkIndex = activeIndex - 1;
			}
			this.props.setActiveWorkIndex(nextWorkIndex);
		}

		/// Handle Up
		if (direction === 'up') {
			if (activeIndex === workLength - 1) {
				nextWorkIndex = 0;
			} else {
				nextWorkIndex = activeIndex + 1;
			}
			this.props.setActiveWorkIndex(nextWorkIndex);
		}
	}

	handleScroll(e) {
		if (window.innerWidth < 768) {
			return;
		}
		let direction = e.deltaY > 0 ? 'down' : 'up';
		let activeIndex = this.props.activeWorkIndex;
		let nextWorkIndex;
		let workLength = this.props.works.length;

		/// Handle Up
		if (direction === 'up') {
			// Check if active is index 0
			if (activeIndex === 0) {
				nextWorkIndex = workLength - 1;
			} else {
				nextWorkIndex = activeIndex - 1;
			}
			this.props.setActiveWorkIndex(
				nextWorkIndex
			);
		}

		/// Handle Down
		if (direction === 'down') {
			if (activeIndex === workLength - 1) {
				nextWorkIndex = 0;
			} else {
				nextWorkIndex = activeIndex + 1;
			}
			this.props.setActiveWorkIndex(
				nextWorkIndex
			);
		}
	}

	render() {
		return (
			<div className="handleWorkHeight" id='mainScreen' onWheel={this.handleScroll}>
				<poseComponents.PageBackGround
					pose={this.props.pageLoaded ? 'loaded' : 'unloaded'}
				>
					<div className="container-fluid">
						<div className="grid__item__container desktopWorkSection">
							<poseComponents.PageContainer>
								<div
									style={{
										width: this.props.canvas.width,
										height: this.props.canvas.height
									}}
									id="canvasRenderer"
								/>
								{this.props.works.map((workItem, workIndex) => (
									<poseComponents.WorkItem
										className="grid__item grid__item--bg"
										id={`workItem${workIndex}`}
										key={workIndex}
										pose={
											this.props.activeWorkIndex ===
											workIndex
												? 'active'
												: 'hidden'
										}
									>
										<div
											className="grid__item-content"
											style={
												this.props.activeWorkIndex ===
												workIndex
													? { zIndex: 10 }
													: null
											}
										>
											<poseComponents.WorkContentGroup
												className="moveWorkContentDown"
												onClick={() =>
													this.props.changeRoute(
														this.props.history,
														`/${workItem.workRoute}`
													)
												}
												style={{ cursor: 'pointer' }}
												pose={
													this.props
														.activeWorkIndex ===
													workIndex
														? 'groupActive'
														: 'groupIdle'
												}
											>
												<poseComponents.WorkContentItem>
													<span className="grid__item-meta">
														{workItem.subtitle}
													</span>
													<span className="barTest" />
												</poseComponents.WorkContentItem>
												<poseComponents.WorkContentItem>
													<h2 className="grid__item-title">
														{workItem.title}
													</h2>
												</poseComponents.WorkContentItem>
												<poseComponents.WorkContentItem
													style={{
														margin: '1rem 0 0 1rem'
													}}
													className="viewWorkCase"
												>
													<span>View Case Study</span>
													<svg
														version="1.1"
														xmlns="http://www.w3.org/2000/svg"
														x="0px"
														y="0px"
														viewBox="0 0 12.7 21.8"
														enableBackground="new 0 0 12.7 21.8"
													>
														<polygon points="12.7,15.5 11.3,14 7.3,18 7.3,0 5.3,0 5.3,18 1.4,14 0,15.5 6.3,21.8 " />
													</svg>
												</poseComponents.WorkContentItem>
											</poseComponents.WorkContentGroup>
										</div>
									</poseComponents.WorkItem>
								))}
								<div id="pagination">
									<div
										className="workNext"
										onClick={() => {
											this.handleNav('up');
										}}
									>
										<svg
											version="1.1"
											xmlns="http://www.w3.org/2000/svg"
											x="0px"
											y="0px"
											viewBox="0 0 12.7 21.8"
											enableBackground="new 0 0 12.7 21.8"
										>
											<polygon points="12.7,15.5 11.3,14 7.3,18 7.3,0 5.3,0 5.3,18 1.4,14 0,15.5 6.3,21.8 " />
										</svg>
									</div>

									<div
										className="workPrevious"
										onClick={() => {
											this.handleNav('down');
										}}
									>
										<svg
											version="1.1"
											xmlns="http://www.w3.org/2000/svg"
											x="0px"
											y="0px"
											viewBox="0 0 12.7 21.8"
											enableBackground="new 0 0 12.7 21.8"
										>
											<polygon points="12.7,15.5 11.3,14 7.3,18 7.3,0 5.3,0 5.3,18 1.4,14 0,15.5 6.3,21.8 " />
										</svg>
									</div>
								</div>
							</poseComponents.PageContainer>
						</div>

						{/* Mobile Section */}
						<div className="grid__item__container mobileWorkSection">
							<poseComponents.PageContainer>
								{this.props.works.map((workItem, workIndex) => (
									<div
										className="grid__item grid__item--bg flex-center"
										id={`workItem${workIndex}`}
										key={workIndex}
										style={{
											background: `url(${
												workItem.backgroundImg
											})`,
											backgroundSize: 'cover',
											borderBottom:
												'1px solid rgba(255,255,255,.5)'
										}}
									>
										<div
											className="grid__item-content"
											style={{ cursor: 'pointer' }}
											onClick={() =>
												this.props.changeRoute(
													this.props.history,
													`/${workItem.workRoute}`
												)
											}
										>
											<span className="grid__item-meta">
												{workItem.subtitle}
											</span>
											<h2 className="grid__item-title">
												{workItem.title}
											</h2>
											<span
												className="grid__item-meta hoverWorkCaseStudy"
												style={{
													display: 'block',
													marginTop: '2em'
												}}
											>
												View Case
											</span>
										</div>
									</div>
								))}
							</poseComponents.PageContainer>
						</div>
					</div>
				</poseComponents.PageBackGround>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		logo: state.nav.logo,
		pageLoaded: state.nav.pageLoaded,
		works: state.works.workPreview.works,
		activeWorkIndex: state.works.workPreview.activeWorkIndex,
		blankImg: state.works.workPreview.blankImg,
		canvas: state.works.workPreview.canvas
	};
};

export default connect(
	mapStateToProps,
	{ ...actions, ...navActions }
)(Works);
