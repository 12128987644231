import React, { Component } from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/* OLD CSS BACKUP */
import './css/bootstrap.min.css';
import './css/mdb.min.css';

/* Master Stylesheet */
import './App.css';
/* React Lightox */
import 'react-image-lightbox/style.css';

import MobileNav from './components/nav/mobileNav/mobileNav';
import DesktopNav from './components/nav/desktopNav/desktopNav';
import Home from './scenes/Home/home';
import About from './scenes/About/about';
import Works from './scenes/Works/works';

import GrimDawnCase from './scenes/Works/scenes/GrimDawnCase';
import GoferGiftCase from './scenes/Works/scenes/GoferGiftCase';
import HvgpCase from './scenes/Works/scenes/HvgpCase';
import TrusouqCase from './scenes/Works/scenes/TrusouqCase';
import GduxCase from './scenes/Works/scenes/GduxCase';

import ScrollToTop from './components/scrollToTop';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <MobileNav />
          <DesktopNav />
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/works" component={Works} />
          <Route exact path="/works/grimdawn" component={GrimDawnCase} />
          <Route exact path="/works/gofergift" component={GoferGiftCase} />
          <Route exact path="/works/hvgp" component={HvgpCase} />
          <Route exact path="/works/trusouq" component={TrusouqCase} />
          <Route exact path="/works/gd-ux" component={GduxCase} />
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
    caseStudies: state.works.caseStudies.caseStudies
  };
};

export default connect(
  mapStateToProps,
  null
)(App);
