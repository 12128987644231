/* Work Animations */
import displacementSlider from './DisplacementSlider';

export const changeRoute = (history, route) => dispatch => {
	dispatch({
		type: 'UPDATE_PAGE_AND_LOGO',
		payload: {
			loaded: false,
			logo: false
		}
	});
	setTimeout(() => {
		history.push(route);
	}, 1000);
};

export const setActiveWorkIndex = newActiveIndex => async dispatch => {
	await dispatch({
		type: 'UPDATE_ACTIVE_INDEX',
		payload: newActiveIndex
	});
};

export const workTransitions = images => dispatch => {
	const el = document.getElementById('canvasRenderer');
	const imgs = images;
	displacementSlider({
		parent: el,
		images: imgs
	});
};

export const setCanvasSize = (width, height) => dispatch => {
	dispatch({
		type: 'WORK_CANVAS_RESIZE',
		payload: {
			width,
			height
		}
	});
};
