import React from 'react';
import { connect } from 'react-redux';
import Lightbox from 'react-image-lightbox';

import * as navActions from '../../../components/nav/services/navActions';
import * as caseActions from './services/WorkCaseActions';
import * as poseComponents from './components/poseComponents';

//// Assets
import GrimDawnMock from '../../../img/grimdawnmock.jpg';
// Video
import GrimDawnVideo1 from '../../../video/casegrimdawn1.mp4';
import GrimDawnVideo2 from '../../../video/casegrimdawn2.mp4';
import GrimDawnVideo3 from '../../../video/casegrimdawn3.mp4';

import WorkDesktopNav from './components/workDesktopNav';
import WorkCaseFooter from './components/workCaseFooter';

class GrimDawnCase extends React.Component {
  componentDidMount() {
    this.props.updatePageLoad(true, true);
  }

  componentWillUnmount() {
    this.props.updatePageLoad(false, false);
  }
  render() {
    return (
      <poseComponents.PageBackGround
        pose={this.props.pageLoaded ? 'loaded' : 'unloaded'}
      >
        <poseComponents.PageContainer>
          <WorkDesktopNav {...this.props} />
          <div class="flex-center container">
            <section id="intro">
              <div id="intro-background" class="row">
                {this.props.lightBoxOpen && (
                  <Lightbox
                    mainSrc={
                      this.props.caseStudy.lightBoxes[
                        this.props.caseStudy.activeLightBoxIndex
                      ].images[
                        this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex
                      ]
                    }
                    nextSrc={
                      this.props.caseStudy.lightBoxes[
                        this.props.caseStudy.activeLightBoxIndex
                      ].images[
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      ]
                    }
                    prevSrc={
                      this.props.caseStudy.lightBoxes[
                        this.props.caseStudy.activeLightBoxIndex
                      ].images[
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length -
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      ]
                    }
                    onCloseRequest={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        false,
                        this.props.caseStudy.activeLightBoxIndex,
                        0
                      )
                    }
                    onMovePrevRequest={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        this.props.caseStudy.activeLightBoxIndex,
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length -
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      )
                    }
                    onMoveNextRequest={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        this.props.caseStudy.activeLightBoxIndex,
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length +
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      )
                    }
                  />
                )}
                <div class="col-xl-12-12 col-lg-12 col-md-12 col-sm-12">
                  <h2 class="h2-responsive caseheadersmall">
                    EXPANSION PROMOTION SITE
                  </h2>
                  <br />
                  <h2 class="h2-responsive caseheader">GRIM DAWN</h2>
                </div>
                <div class="col-xl-12-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <img
                    class="img-responsive mockimage"
                    src={GrimDawnMock}
                    alt="grimdawnmockhero"
                  />
                </div>
              </div>
            </section>
          </div>
          <main className="main-content">
            <div className="container">
              <div className="row flex-center">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <div className="hexagonCaseStudy">
                    <p>01</p>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <h2 className="workheader">A NEW DAWN</h2>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <p className="workdescriptiontag">
                    The Fallen City of Malmouth
                  </p>
                </div>
                <div className="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <p className="workdescription">
                    Grim Dawn is an action role-playing game that recently
                    released a new expansion, Ashes of Malmouth. For this
                    project, I created a one page site that highlights features
                    and promotes this new expansion. I've really enjoyed playing
                    this game in the past and wanted to try my hand at building
                    out a site that compliments their work.
                  </p>
                </div>
              </div>
              <div className="row flex-center">
                <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        0
                      )
                    }
                  >
                    <img
                      className="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[0]}
                      alt="grimdawn1"
                    />
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        1
                      )
                    }
                  >
                    <img
                      className="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[1]}
                      alt="grimdawn2"
                    />
                  </div>
                </div>
              </div>
              <div className="row flex-center">
                <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        2
                      )
                    }
                  >
                    <img
                      className="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[2]}
                      alt="grimdawn3"
                    />
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        3
                      )
                    }
                  >
                    <img
                      className="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[3]}
                      alt="grimdawn4"
                    />
                  </div>
                </div>
              </div>

              <div className="row flex-center">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <div className="hexagonCaseStudy">
                    <p>02</p>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <h2 className="workheader">Grim & Green</h2>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <p className="workdescriptiontag">DESIGN AND PROMOTION</p>
                </div>
                <div className="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <p className="workdescription">
                    This project required a lot of time editing the media,
                    created by Crate Entertainment, so that it would work in the
                    site. I manually separated out a Ashes of Malmouth
                    background image into 7 layers to create a parallax effect,
                    created multiple sprites for section dividers and
                    navigation, and used Sony Vegas to edit some of the Ashes of
                    Malmouth promotion videos to accent specific sections on the
                    site. With all this media, I had to optimize and compress
                    the many images and videos using Optimizilla and Handbrake
                    respectively.
                  </p>
                </div>
              </div>
              <div className="row flex-center">
                <div className="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        1,
                        0
                      )
                    }
                  >
                    <img
                      className="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[1].images[0]}
                      alt="grimdawn5"
                    />
                  </div>
                </div>
              </div>
              <div className="row flex-center">
                <div className="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        1,
                        1
                      )
                    }
                  >
                    <img
                      className="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[1].images[1]}
                      alt="grimdawn7"
                    />
                  </div>
                </div>
              </div>
              <div className="row flex-center">
                <div className="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        1,
                        2
                      )
                    }
                  >
                    <img
                      className="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[1].images[2]}
                      alt="grimdawn6"
                    />
                  </div>
                </div>
              </div>
              <div className="row flex-center">
                <div className="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        1,
                        3
                      )
                    }
                  >
                    <img
                      className="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[1].images[3]}
                      alt="grimdawn8"
                    />
                  </div>
                </div>
              </div>
              <div className="row flex-center">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <div className="hexagonCaseStudy">
                    <p>03</p>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <h2 className="workheader">Shaping a World</h2>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <p className="workdescriptiontag">An Immersive Experience</p>
                </div>
                <div className="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <p className="workdescription">
                    This page needed to a way to come to life and make users
                    feel like they are a part of this apocalyptic world of Grim
                    Dawn. So, I came up with a few exciting ways to show off the
                    artwork and allow users to interact with it. Starting off, I
                    created a parallax effect at the top of the page that uses 7
                    different layers to create a dynamic image. As the user
                    scrolls, the layers move at different speeds in order to
                    create the layered effect.
                  </p>
                </div>
              </div>
              <div className="row flex-center">
                <div className="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <video
                    className="workvideo"
                    src={GrimDawnVideo1}
                    autoPlay
                    loop
                  />
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        2,
                        0
                      )
                    }
                  >
                    <img
                      className="img-responsive workimages mobileshow"
                      src={this.props.caseStudy.lightBoxes[2].images[0]}
                      alt="grimdawn10"
                    />
                  </div>
                </div>
              </div>
              <p className="workdescriptiontag" style={{ marginTop: '10px' }}>
                PARALLAX HEADER
              </p>
              <div className="row flex-center">
                <div className="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <p className="workdescription bumpdown">
                    Next up, the navigation got revamped to relate to the game's
                    content. By using Grim Dawn's Devotion system, I came up
                    with an idea for the desktop navigation. The Devotion system
                    allows players to customize their skills with additional
                    Celestial Powers by unlocking constellations. In the
                    navigation, a different constellation accompanies a section
                    label that appears after the user hovers on the
                    constellations. Each constellation will light up
                    individually when hovered over by using CSS and a sprite
                    created in Photoshop.
                  </p>
                </div>
              </div>
              <div className="row flex-center">
                <div className="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <video
                    className="workvideo"
                    src={GrimDawnVideo2}
                    autoPlay
                    loop
                  />
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        2,
                        1
                      )
                    }
                  >
                    <img
                      className="img-responsive workimages mobileshow"
                      src={this.props.caseStudy.lightBoxes[2].images[1]}
                      alt="grimdawn9"
                    />
                  </div>
                </div>
              </div>
              <p className="workdescriptiontag" style={{ marginTop: '10px' }}>
                DESKTOP NAVIGATION
              </p>
              <div className="row flex-center">
                <div className="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <p className="workdescription bumpdown">
                    I always have a simple motto when I play games: "Look good
                    to play good". I know many players want to create a unique
                    and striking look for their characters too. Using some of
                    the media from Grim Dawn's design team, I created a lightbox
                    (desktop) and carousel (mobile) that show off some of these
                    incredible items.
                  </p>
                </div>
              </div>
              <div className="row flex-center">
                <div className="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <video
                    className="workvideo"
                    src={GrimDawnVideo3}
                    autoPlay
                    loop
                  />
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        2,
                        2
                      )
                    }
                  >
                    <img
                      className="img-responsive workimages mobileshow"
                      src={this.props.caseStudy.lightBoxes[2].images[2]}
                      alt="grimdawn11"
                    />
                  </div>
                </div>
              </div>
              <p
                className="workdescriptiontag"
                style={{
                  marginTop: '10px',
                  paddingBottom: '0px',
                  marginBottom: '0px'
                }}
              >
                UNIQUE ITEMS SECTION
              </p>
              <div className="row flex-center">
                <div className="col-xl-10 col-lg-8 col-md 12 col-sm-12 flex-center">
                  <a
                    className="projectlink"
                    href="https://d3nu30zcqwx59h.cloudfront.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    VISIT THIS PROJECT
                  </a>
                </div>
              </div>
            </div>
            <WorkCaseFooter />
          </main>
        </poseComponents.PageContainer>
      </poseComponents.PageBackGround>
    );
  }
}

const mapStateToProps = state => {
  return {
    logo: state.nav.logo,
    pageLoaded: state.nav.pageLoaded,
    caseStudy: state.works.caseStudies.caseStudies[0],
    caseStudyIndex: state.works.caseStudies.caseStudies.findIndex(
      caseStudy => caseStudy.caseName === 'grim-dawn'
    ),
    lightBoxOpen: state.works.caseStudies.lightBoxOpen
  };
};

export default connect(
  mapStateToProps,
  { ...navActions, ...caseActions }
)(GrimDawnCase);
