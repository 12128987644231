import React from 'react';
import posed from 'react-pose';

const SVnamePaths = posed.g({
  svNameIdle: {
    staggerChildren: 15
  },
  svNameActive: {
    staggerChildren: 25
  }
});

const SVNamePath = posed.path({
  svNameIdle: {
    opacity: 0,
    fill: '#171717',
    x: -30,
    transition: { ease: 'linear', duration: 300 }
  },
  svNameActive: {
    fill: '#a5550a',
    x: 0,
    opacity: 1,
    transition: { ease: 'linear', duration: 500 }
  }
});

export default props => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="439 -265 689 800"
    style={{ enableBackground: 'new 439 -265 689 800' }}
    space="preserve"
    className="SVNAMESVG"
  >
    <SVnamePaths pose={props.SVnameAnimation ? 'svNameActive' : 'svNameIdle'}>
      <SVNamePath
        className="SVNameSVG1"
        d="M824.6,28.5c-0.2-1.3-0.3-2.8-0.5-4.5c-0.4-3.3-0.4-5.7-0.1-7.4c-3.5-0.9-7.6-1.4-12.6-1.4
		c-1.6,0-3.1,0-4.5,0.1c1,3.9,1.6,7.3,2.1,10C813.9,25.8,819.1,26.8,824.6,28.5z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M992.4-55.9l-28.3-9.9c0,0-6.3-8.3-8-19.6c-0.8,5.8-2.1,9.5-2.2,10.1c-0.1,0.7-1.1,1.1-2.1,1.3
		c0,0.2,0,0.5-0.1,0.7L908-78.8c0,0-1.1-2.6-2.6-6.8l-2.8-0.8c0,0-0.3-1.1-0.7-3l-2.2-0.1c-0.3,0.2-0.7,0.3-1,0.5
		c2.2,10.3,3.7,22.3,4.2,36.4c0,0,9.4,42.8,68.3,29.4c0,0,24.1-10.3,33.9-31.9c-0.5,0.2-0.9,0.4-1.3,0.5
		C1001-53.6,996.7-53.9,992.4-55.9z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M661.4,388.4c1.8-3.2,3.8-6.4,6.4-9.4c-17.9-4.2-37.6-14.5-56.8-35.5c0,0-5-7.1-13.9-15
		c4.1,10.2,9.2,20,15.3,29.3c18.9,14.2,21.5,25.5,47.4,30.4C660.3,388.2,660.8,388.3,661.4,388.4z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M940.5-139.6c2.9,3.3,5.7,7.5,8.3,12.7c2.9,5.7,4.8,11.3,6,16.4l8-0.4c2.9-3.7,6.8-7.4,12.1-10.8h0.8
		c0.1-2.3-0.6-5.2-3.4-8.7c0,0,8.7,0.7,11,6.6c0.2,0.6,0.4,1.2,0.6,1.9l3.4-0.1c-7.4-8.8-17.1-18.1-29.4-28.1c0,0-1.8-1.5-5.1-4
		C950-149.1,946.1-144.2,940.5-139.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M1004,136.6c0,0-7.8-4.9,2.3-4.2c0,0,3,1,4.4-1.8c0,0,1.6,0.4,2.6,1.6c-0.3-0.8-0.5-1.5-0.8-2.3h-14.4
		c0,0,0.2,2.9-0.2,6.7C998.7,135.8,1000.5,135.7,1004,136.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M898.5-108.3c-1.3-5.6-2.4-11.3-2.7-16.3c-3.6,0.1-7.5,0.1-11.6-0.1c3.4,4.8,6.5,10.5,9.1,17.4l5.3-0.3
		C898.6-107.8,898.5-108,898.5-108.3z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M984.7,435.5c-24.9-42.3-79.2-23.8-79.2-23.8c-47.9,9.7-75.9-10-90.7-27.2c1.2,4.3,3.2,9.2,6,14.7
		c3.6,4.4,11.4,12,26.3,19c22.9,10.9,30.1,2,55.3,3s33.1,16.2,34.3,29.3c1.1,12.3-20.9,18.3-25,7.6c-21.8,11.8-64.4,24.7-113.8-13.7
		c0,0-45.4-45.7-91.7-15.6c0,0,9.6,5.5,13.5,14.5c2.7,1.2,5.4,2.3,8.2,3.5c-0.6-6.8,5.6-14.6,17-17.6c19.6-5.2,48.2,17,48.2,17
		c-33.7-13.3-43.3-0.1-46.1,7.7c5,1.7,10.1,3.3,15.2,4.8c8.6-4.6,20.2-5.7,35.4,1.1c7.5,3.4,13.1,6.8,17.9,10.1
		c44.2,5.9,89.4,3.9,130-7.1c0.2-3.9-2.3-8-2.3-8c3.9-0.8,7.9,1.8,10.4,5.7c2.9-0.9,5.8-1.8,8.7-2.8
		C960,437,984.7,435.5,984.7,435.5z M941.3,443.6c0,0,10.9-25-32.6-28.7c0,0,31.6-6.4,45.1,5.5C968.4,433.3,962.4,452.2,941.3,443.6
		z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M920.7,440.7c0.1-0.6,0.4-1.1,0.7-1.6c-1.5-2.1-6.5-5.5-22.3,1c-17.2,7.1-27.8,11.4-41.9,7
		c14.9,5.5,33.1,8.5,53.8,4.7C911.7,444,917.9,441.4,920.7,440.7z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M1014.3,234.1c-0.6,5.9-2.8,11.6-2.8,11.6c-0.5,5.6,11.2-3.8,11.2-3.8c-1.1-4.4-1.3-6.9-1.1-8.1
		C1019.5,234.8,1016.8,235,1014.3,234.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M844.8,101.8c-1.4,0.3-2.7,0.5-3.9,0.4c-0.8,0-1.6-0.1-2.3-0.3c1.4,4.6,2.4,10,2.5,16.1v0.1
		c2,0.9,4.1,1.9,6.2,2.9C848.1,116.2,847.6,110,844.8,101.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M739.5-114.8c3.9,34.1,25,56.1,25,56.1c19.9,19.5,31.1,40.7,37.4,57.8c6-4.2,12.6-7.3,19.2-7.6
		c4.5-0.3,8.5,0,12,0.6c-2.5-4-8.3-8.9-21-13.8c0,0,34.3,1.6,33.8,14.4c-0.1,1.7-0.3,3-0.7,4.2c6.8,4.4,12.7,11.5,20.3,20.8
		c14.8,18.1,20,10.1,20,10.1s-7.8-5.5-5.2-15.1c-0.3,0.2-0.6,0.4-0.9,0.5c-16,8.9-18.4-8.4-21.1-19c-2.7-10.7-9.2-10.6-9.2-10.6
		c10.4-2.7,33.5,11.9,34.6,22.5c7.9-8.6,24.6,7.2,18.7,18.6c-6.4,12.2-19.5,23.2-41.7,12.7c-13.9-6.6-20.2-13.6-28.8-18
		c1.1,2.5,1.8,5.6,1.6,8.4c-0.1,1.3-0.3,2.5-0.5,3.6c8.5,3.6,17.4,9,26.6,16.6c0,0,39.5,42.5,78.5,13c0,0-20.9-12.7-10.4-31.5
		c1.3-2.3,3.1-4.4,5.3-6c-2.6-1.8-5.3-3.6-8-5.4c-2.9,7.7-12.4,13.1-17.7,8.7c0,0,7-8.6,5.6-16.6c-16.5-10.4-34.4-20.7-54-30.7
		c0,0-7.6-4.7-17.8-12.3c-3.1,2.8-7.7,5.1-14.5,6.6C786.4-17.5,754-90.9,754-90.9c32.5,34.2,51.7,40.1,62.7,37.8
		c-28.2-26.9-51.5-64.2,0.6-87.9C797.7-143.5,765.8-142.3,739.5-114.8z M904.9,61.6c-16.6,5.3-41.4-15.2-41.4-15.2
		c39.3,16.2,40-12.9,40-12.9C925.3,34.8,922.8,55.8,904.9,61.6z M753.2-106.6c-5.1-14.8,13.2-27.9,23.1-11.3
		c9.9,16.6-2.4,23.6,9.7,44.2C786-73.7,758.3-91.7,753.2-106.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M739.4-115.9c4.4-10.2,28.6-57.3,95.7-46.3c0,0,46.5,13.8,70.1-18.8c-5.6-2.3-11.6-4.5-17.9-6.5
		c0.2,5.1-2,11-7.1,15.7c-12.3,11.4-42.2,6.5-42.2,6.5c30.2-3.5,31.9-19.1,30.8-26.9c-2.1-0.4-4.2-0.8-6.4-1.2
		c-2.4,10.6-10.4,21.1-30.5,22.5c-23.7,1.7-30.7-6.2-51.9,4.5c-21.2,10.7-26.5,22-26.5,22s4.7-23.1,16.7-37.1
		c-6.1,2.8-12.3,6.1-18.4,9.9c0,0-0.8,0.6-2.3,1.9C739.8-149.9,737.7-131.6,739.4-115.9z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M630.2,319.3c0,0,19.1,32.1,57.1,45.1c10.3-4.5,23.7-6.7,41.1-4.7c0,0,49,9.6,84.4,57.7c0,0,12.3,14.4,33.6,25
		c-7.6-4.2-13.5-12.2-17.9-20.4c-14.1-11-34-29.3-39.7-48.9c-1.2-0.4-2.4-0.8-3.6-1.2c0,0-62.1-27.9-21.8-99.9l-3.2-2
		c-12.9,24.3-34.1,53.5-56,38.3c0,0-24.4-15.4-18.1-58.2c1.3-8.9,3.6-16.8,6.4-23.7l-9.7-6.2c-6.4,1.8-13.9,2.6-22.8,2.1l-38-26.2
		c-8.7-3.2-12-35.5,0-54.2s48.2-24.1,59.6-20.1c3.9,1.4,8.6,5.3,12,11.2l24.8,34.8c0,0,0.5,14.7-6.7,28.8c5.6-5.9,10.1-9,10.7-9.6
		c1.7-1.7,7,2.3,7,2.3l40.5,48.2c0,0-0.1,0.3-0.3,0.7l3.3,4c0,0-0.5,1.5-1.6,4l5.9,4.8c0.4-0.6,0.8-1.1,1.3-1.7
		c0,0,78.1-85.8-2.7-133.1c0,0-167.5-81.9-188.1,118.2c8.6,10.6,16.4,25.5,22.9,49.3c0,0-8.3-15.5-24.5-28.6c-0.1,3-0.3,5.9-0.4,9
		c0,0.5,0,1,0,1.6C600.3,276.8,617.4,294.1,630.2,319.3z M651.3,313.4c-2-6.5-8.5-20.1-5.5-38.2c3-18.1,17.3-4.3,17.3-4.3
		C649.1,277.7,651.3,313.4,651.3,313.4z M716.8,321.7c23,10.8,42.7,16.8,63.8,62.8c0,0-26.4-34.4-65.5-33.2s-41.3-8.9-39.5-17.9
		C677.3,324.4,693.8,310.9,716.8,321.7z M671.9,332.5c0,0-8.8-5.7-7.8-13.3s10-21.5,16.4-2.6C680.6,316.6,669.4,317.5,671.9,332.5z
		 M675.3,244.3c6,8.9-7.1,18.2-7.1,18.2c2.1-11.9-8.3-9.1-8.3-9.1C659.8,242.5,669.3,235.4,675.3,244.3z M617.8,250.4
		c18.9-32.9,42.4,2,38.4,8.3c0,0-43.2-0.3,1.3,80.5C657.5,339.2,597.3,286,617.8,250.4z M609.3,262.8c-1.5-26.9-11.5-28-14.1-28
		c-2.6,0-1.7-12,10.7-8.6C618.2,229.6,609.3,262.8,609.3,262.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M849.7-189.2c0,0-6.9-0.6-9.7-6.4c-9.4-0.3-19.1,0.2-29.1,1.8c5.1,1.3,10.7,3.3,17.2,5.7
		C848.3-180.7,849.7-189.2,849.7-189.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M738.3,45.3c36.3-41.6-2.6-93-2.6-93c-31.8-55.5-11.6-94.4,3.3-112.7c-6.8,6.4-15.6,15.4-24.4,26.4
		c-2.6,6.2-5.2,14.8-6.6,26.3c-3.5,28.3,8.5,32.8,16.4,60.1c7.9,27.2-5.5,41.1-19.1,47c-13.6,5.9-28.8-18.7-14.7-25.4
		c14.2-6.7,20.3,6.2,20.3,6.2s9.1-5.1-7.8-24.6c-10.3-11.9-17.5-20.4-20.6-30.9c-7.1,23.6-7.7,50,4.7,77.3c0.3-0.2,0.4-0.3,0.4-0.3
		c6.7,18.2,19.2,3.5,19.2,3.5c3.2,5.6-1.1,13.8-7.5,17.7c1.7,2.5,3.6,4.9,5.6,7.4c0.6-0.4,1.3-0.7,2-1.1
		C727.9,19.4,738.3,45.3,738.3,45.3z M714.2,1.7c0,0,30.5,2.8,19.1-44.9c0,0,18,31.4,10.1,50.1C734.8,27.1,712.5,27.3,714.2,1.7z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M1002.8,192.1c0,0,0.1,2.8,0,6.2l4.9-6.5L1002.8,192.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M1005.7,181.5l-3.5,0.8l0.2,5.1l5.4,4.4l4.4-5.9C1012.2,185.9,999.4,183.3,1005.7,181.5z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M983.8,64.2c0,0.2,0,0.3,0,0.5c0,0,2.5,69.2-63.7,62.5c0,0-42-8.1-73-53.1c0,0-14.4-18.1-38.5-27.4
		c-3.7,23.6-14.9,36.9-27.5,44.2c8.7,4.9,18.1,9.7,28.2,14.6c0,0,9.3,2.9,23.1,8.7c0.8-5.1,0.7-10.9,0.2-16.2
		c-2.4-2.6-3.8-5.4-3.8-5.4c1.2,0.4,2.3,0.6,3.3,0.8c-0.8-5.9-1.7-10.1-1.7-10.1c1.2,2.4,3.2,5.9,5.2,10.5c2.3,0,4-0.6,5.4-1.4
		c-4.1-8.8-10.5-19.3-20-32c0,0,55.1,36.3,48.4,72.1c1.4,0.8,2.8,1.6,4.2,2.5c-0.3-6.5,0-11.9,0-11.9c1.5,6.7,3.2,11.5,4.9,14.9
		c54,33.4,110.7,93.1,52.8,181.2c9.5-1.2,19.9,2.9,17.8,21.9c-3.9,36.3-75,43.1-75,43.1c52.4-24.6,52.3-43,47.6-51.7
		c-1.3,1.7-2.6,3.4-4,5.1c0.1,1.7-0.3,3.9-1.7,6.7c-7.3,14.6-19.1,20.9-23.9,24.6c0,0,3.8-4.6,7.6-10.8c-0.3,0.3-0.6,0.6-0.9,0.9
		c0,0-25.5,25.5-74,21.6c39.5,20.6,79.4,3.5,79.4,3.5c47.2-24.3,90-12.6,90-12.6c28.9,10.5,39,27.7,41.4,43.5
		c25.3-23.1,43.7-53.4,51.8-91.6C1087.6,323.6,1122.1,190.9,983.8,64.2z M990.5,124.2l27.5,2.4l5.8,7.6c0,0-3.8,24.4,8,23.5
		l5.3,11.5c0,0-10.3,2.3-23-34.8c0,0.8-0.2,1.7-0.8,2.8c0,0-3.5,3-3.8-1l0.2,4.1c0,0,2,1.1,3.8-0.5s3.4,8.2,1.3,10
		c0,0,7.1,12.7,0.6,9.1c0,0-1.2-4.1-5.9-5.4c0,0-13.2,4.2-13.6-7l7.6,2.7l0.4-2.3c0,0-5.2-5.1-6.3-8.3c-1.1,6.7-4.1,14.8-12.6,16
		C985,154.6,997.1,136.1,990.5,124.2z M928.4,165c-21-10.3-39-15.9-59.1-61.2c0,0,24.6,33.6,60.1,31.7c35.4-1.9,37.6,8.1,36.2,17.1
		C964.1,161.6,949.4,175.3,928.4,165z M966.5,356.1c-1.9-1.4-9.7-5.8-24.8,12.8c0,0,10.9-28.5,21.6-24.5
		C974,348.4,968.4,357.5,966.5,356.1z M1006.7,277.7l8.4,1.9l0.3,42.2l6,4.9l6.3-22c12.1-6.7,6,4.9,6,4.9
		c-1.1,14-11.5,36.5-11.5,36.5l1.6-14.1l-39.1,5.4c-9.1-9.1-0.5-31.8-0.5-31.8c9.5-5.5,10.9,8.9,10.9,8.9c-0.2,24.6,9.6,9.5,9.6,9.5
		 M1010.4,257c-3.6,20.4-22.7,10.9-22.7,10.9c15.5-2.1,16.2-13.5,16.2-13.5l-8.4-3.4l-2.6-21.2c8.5,7.6,13.1,22,13.1,22l-0.6-24.7
		c-0.3-2.6-0.4-4.4-0.3-5.8c-5.6-11.4-15.6-13-15.6-13s2.7,0.1,6.7,0.8c-4.6-5.4-2.4-14.3-2.4-14.3c-12.7,1.2-6.2-5.1-6.2-5.1
		l4.7-6.8c0,0-15.8-7.5,1.6-7.3l-0.6-16.2l8.3,6.8l1.3,9l4.5,6.1l5.6-0.6l-4.6-12.4l9.7,1.9l-0.7,9.3c0,0,16,5.7,9.9,8l-9,2.6
		l13.6,21.4l-10.8-3.7l-2.8-9.2c0,0,0.7,13.6-0.2,18.9c1.6,1.2,3.1,2.7,4.4,4.3c4,5,3.2,9.1,0.4,11.2c1.5,0.8,3.6,4.2,3.6,4.2
		c10.1,10.9-9.7,11.9-9.7,11.9c-1.2,16.3,21.7,30.6,21.7,30.6C1019.6,286.9,1010.4,257,1010.4,257z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M1013.4,198.2l-10.7,2.2c-0.2,3.8-0.8,7.8-2,9.6c4.4,1.1,9.5,2.7,14.1,5.4
		C1012.6,208.1,1013.4,198.2,1013.4,198.2z"
      />
      <SVNamePath className="SVNameSVG1" d="M782.6,307.2L782.6,307.2z" />
      <SVNamePath
        className="SVNameSVG1"
        d="M820.9,294.5c-8.8,12.7-24.5,18.2-36.1,13.8l27.8,12.3c44.2-8.4,28.1-47.8,28.1-47.8l-20.4-16.7
		C829.6,264.8,830.1,281.2,820.9,294.5z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M808.2,265.4c0.2,0.1,0.5,0.2,0.7,0.3c-0.9-3.1-2.6-5.5-5.1-6.8c-3.9-2-8.9-0.9-13.4,2.5l7.4,5.9
		C801.1,265,804.9,264.1,808.2,265.4z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M784.6,296.4c2.9,1.5,6.5,1.2,9.9-0.4c-2.5-1.4-4.2-4-4.9-7.2l-10.7-6.9C778.3,288.5,780.2,294.1,784.6,296.4z
		"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M714.3,306.5c-17.3-10.2-28.6-42.2-15.2-76l-6.5-4.2c-2.8,6.9-5.1,14.7-6.4,23.7
		c-6.4,42.8,18.1,58.2,18.1,58.2c21.9,15.2,43.1-14,56-38.3l-3.7-2.4C745.2,289.7,728.6,314.9,714.3,306.5z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M771.3,246.2c1-2.5,1.6-4,1.6-4l-3.3-4c-0.3,0.9-1,2.6-2,5L771.3,246.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M711,197.8l5.5,4.4c3.8-4.4,8-8.7,12.9-12.8c0,0-5.4-4-7-2.3c-0.6,0.6-5.1,3.7-10.7,9.6
		C711.4,197,711.2,197.4,711,197.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M710.1,198.2c0.5-0.5,1-1.1,1.5-1.6c7.2-14.1,6.7-28.8,6.7-28.8L693.5,133c6.6,11.3,8.5,29.7-8.6,50.4
		c-26.1,31.4-62.9,12.7-62.9,12.7l38.1,26.1c8.9,0.5,16.4-0.4,22.8-2.1l-8.1-5.2C674.8,214.8,689.2,208.4,710.1,198.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M711,197.8c0.2-0.4,0.4-0.8,0.6-1.1c-0.5,0.5-1,1-1.5,1.6C710.4,198.1,710.7,197.9,711,197.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M710.1,198.2c-20.9,10.2-35.3,16.6-35.3,16.6l8.1,5.2c7.2-2,13-5.3,17.6-9.2
		C703.7,205.8,707,201.6,710.1,198.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M700.5,210.9c4.6-3.9,8-8.4,10.5-13.1c-0.3,0.1-0.6,0.3-0.8,0.4C707,201.6,703.7,205.8,700.5,210.9z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M640.7,182L640.7,182c-4.8-6.6-3-18.1,4.8-27.2c8.6-10.1,21.4-13.4,28.4-7.4c1.9,1.7,3.2,3.9,3.9,6.4
		c2.3-7,2.5-24.6-10.4-24.6c-14.6,0-36.8,12.7-40.8,28.2C625.3,162.3,626.1,182,640.7,182z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M854.1-96.5c-0.2-11,6.5-20.8,14.9-23.1l-20.8,4.5c-21.7,23.3,3.6,39.6,3.6,39.6l18.4,1.2
		C861.3-75.6,854.3-85,854.1-96.5z"
      />
      <SVNamePath className="SVNameSVG1" d="M870.6-120L870.6-120z" />
      <SVNamePath
        className="SVNameSVG1"
        d="M870.4-106.1l9.3-0.5c-1.3-2.5-2-4.2-2-4.2c1.4,1.5,2.7,2.9,3.9,4.1l1.4-0.1c-2.3-4.1-5.7-6.6-9.1-6
		c-5.2,0.8-8.4,8.1-7.1,16.3c1.2,8.2,6.4,14.2,11.5,13.4c3-0.5,5.4-3.2,6.6-7l-10.2-0.6L870.4-106.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M902-89.2c0.4,1.9,0.7,3,0.7,3l2.8,0.8c-0.4-1.1-0.8-2.3-1.3-3.6L902-89.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M898.5-108.3c0,0.2,0.1,0.5,0.1,0.7h0.1C898.6-107.8,898.6-108,898.5-108.3z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M947.2-110.1l7.6-0.4c-1.2-5.2-3.1-10.7-6-16.4c-2.6-5.2-5.4-9.3-8.3-12.7c-2.8,2.3-6,4.4-9.7,6.5
		C937.3-127.8,943.3-120,947.2-110.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M956-86.3l-3.8-0.2c0.2,4,0.1,8.2-0.3,12.5c1-0.2,2-0.6,2.1-1.3c0.1-0.6,1.4-4.3,2.2-10.1
		C956.1-85.7,956-86,956-86.3z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M1007.2-122.5l-20.1,0.5c1.7,2,3.3,4,4.8,5.9C999.6-121.3,1007.2-122.5,1007.2-122.5z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M972.2-115.6c0,0,3.2-2.1,3.5-6.2h-0.8c-5.3,3.4-9.2,7.1-12.1,10.8l6.7-0.3c0,0-5.5,9.7-13.3,24.4
		c-0.1,0.5-0.1,1-0.2,1.5c1.7,11.2,8,19.6,8,19.6l28.3,9.9c-8.4-3.8-17-13.7-15.7-32.9c1-14.1,8.1-22.4,15.2-27.3
		c-1.5-1.9-3.1-3.9-4.8-5.9l-3.4,0.1C985.2-115.6,983.2-105.5,972.2-115.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M956.1-85.3c0.1-0.5,0.1-1,0.2-1.5c-0.1,0.2-0.2,0.4-0.3,0.6C956-86,956.1-85.7,956.1-85.3z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M956.3-86.9c7.7-14.7,13.3-24.4,13.3-24.4l-6.7,0.3c-3.3,4.2-5.2,8.5-6.2,12.7C956.9-94,956.7-90.1,956.3-86.9
		z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M956.6-98.3c-1,4.2-1.1,8.2-0.6,12c0.1-0.2,0.2-0.4,0.3-0.6C956.7-90.1,956.9-94,956.6-98.3z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M994.5-112.6c6.4-6.4,12.7-9.9,12.7-9.9s-7.6,1.2-15.3,6.4C992.9-114.9,993.7-113.8,994.5-112.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M994.5-112.6c-0.8-1.1-1.7-2.3-2.6-3.5c-7.1,4.9-14.3,13.2-15.2,27.3c-1.3,19.2,7.3,29.1,15.7,32.9
		C971.3-81.3,983.2-101.4,994.5-112.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M1010.7-88.6c-0.2,3-1,5.7-2.1,8.1c0.9,5.9,0.6,11.4-0.5,16.4c0.2-0.1,0.4-0.2,0.6-0.4
		c8.3-6.1,15.7-22.8,11.7-33.4c-1.3-3.4-9.8-14.6-18.1-8.5C1007.7-104.6,1011.4-97.2,1010.7-88.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M1008.6-80.5c-2.4,5.2-6.6,8.5-11,8.1c-1.8-0.2-3.4-0.9-4.8-2.1c1.5,4.9,8.3,14.8,15.4,10.4
		C1009.2-69.2,1009.5-74.6,1008.6-80.5z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M1013.1,225c1.4,2.5,1.5,5.8,1.2,9.1c2.5,0.9,5.2,0.7,7.2-0.3c0.2-1,0.7-1.1,1.3-0.8c2.8-2.2,3.6-6.3-0.4-11.2
		c-1.3-1.6-2.8-3-4.4-4.3c-0.4,2.1-1,2.9-2,0.8c-0.4-0.9-0.8-1.9-1.1-3c-4.5-2.7-9.7-4.4-14.1-5.4c-0.8,1.2-1.8,1.5-3.2,0.3
		c-0.5-0.4-0.9-0.8-1.3-1.3c-4-0.7-6.7-0.8-6.7-0.8s10,1.6,15.6,13C1005.7,213,1013.1,225,1013.1,225z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M1016,218.3c1,2.1,1.7,1.3,2-0.8c-1-0.8-2.1-1.5-3.2-2.1C1015.1,216.4,1015.5,217.4,1016,218.3z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M876-134.1c-7.1,2-13.1,0.8-15.6-4.5c-0.9-2.1-1.4-4-1.6-5.6c-2-0.7-4-1.2-5.9-1.6c-0.1,4.9-7.9,8.8-13.9,6.3
		c-2.6-1-6.6-3.3-12.5-5.2c-3.4,1.2-6.5,2.5-9.5,3.8c10.8,1.5,17.7,4.1,17.7,4.1c18,8.4,35.2,11.4,49.4,12.1
		C881.6-128.4,878.8-131.5,876-134.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M909.7-179L909.7-179c2.4,1.1,4.8,2.2,7.1,3.3c0.4-1.9,0.9-3.8,1.3-5.6c4.9-19.3,38.3-20.9,34,14.7
		c-0.4,3.7-1.4,7-2.8,10c1.3,0.9,2.4,1.7,3.4,2.5c12.7-23.7-2.4-49.9-2.4-49.9c-23.4-38-61.6-21.3-63.2-2.2
		c-1.6,19,21.7,19.7,21.7,19.7c-1.1,2-2.3,3.9-3.6,5.7C906.7-180.3,908.2-179.7,909.7-179z M900.2-194.2c-9-4.3-8.9-9.5-8.8-13
		c0.1-5.8,5-8.9,5-8.9c35.8-23.2,56.2,24.3,56.2,24.3C923.1-212.9,920.8-184.5,900.2-194.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M835.1-162.1c-67.1-11-91.3,36.1-95.7,46.3c0,0.3,0.1,0.7,0.1,1c26.3-27.4,58.2-28.7,77.7-26.1
		c2.9-1.3,6.1-2.6,9.5-3.8c-13.2-4.3-35.6-6.7-71.2,10.4c0,0,29.6-28.8,71.4-23.9c20,2.3,26.3,7.8,26.2,12.3
		c1.9,0.4,3.9,0.9,5.9,1.6c-1-11.7,16-13.2,16-13.2c33-6.9,34.7-21.2,34.7-21.5c-1.5-0.7-3-1.3-4.6-2
		C881.5-148.3,835.1-162.1,835.1-162.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M937.5-142.8c1,1,2,2,3,3.2c5.6-4.5,9.5-9.5,12.2-14.5c-1-0.7-2.1-1.6-3.4-2.5
		C946.6-150.9,942.4-146.4,937.5-142.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M895.8-124.6c-0.1-1.7-0.1-3.3,0-4.8c-1.7,0.1-2.7,0.2-2.7,0.2c1-0.5,2-1.1,2.9-1.7c0.4-3.7,1.4-6.8,3.4-8.8
		c1.9-6.6,5.5-11.5,11.7-13c2.5-7.3,4.1-15.3,5.8-22.9c-2.3-1.1-4.6-2.2-7.1-3.3c1.5,22.1-18.5,40.7-33.8,44.9
		c2.9,2.6,5.6,5.7,8.2,9.4C888.4-124.5,892.3-124.5,895.8-124.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M930.8-133.2c3.7-2,6.9-4.2,9.7-6.5c-1-1.2-2-2.2-3-3.2c-3.5,2.5-7.4,4.6-11.3,6.3
		C927.7-135.5,929.3-134.4,930.8-133.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M906.4-142.4c1.9-3.2,3.4-6.7,4.6-10.4c-6.2,1.5-9.8,6.4-11.7,13c1-1.1,2.4-1.8,4-2.2
		C904.3-142.2,905.3-142.3,906.4-142.4z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M899.3-139.8c-2,2-3,5.1-3.4,8.8c0.7-0.5,1.3-0.9,1.9-1.4C898.2-135,898.6-137.5,899.3-139.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M897.6-129.6c-0.7,0.1-1.3,0.1-1.8,0.2c-0.1,1.5-0.1,3.1,0,4.8c0.5,0,1.1,0,1.6-0.1
		C897.4-126.3,897.5-128,897.6-129.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M906.4-142.4c-1.1,0.1-2.1,0.2-3.1,0.4c-1.6,0.4-2.9,1.1-4,2.2c-0.7,2.3-1.1,4.8-1.4,7.4
		C901.4-135.2,904.1-138.6,906.4-142.4z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M897.4-124.7c13.8-0.7,22.5-3.3,22.5-3.3c4-1.6,7.6-3.3,10.9-5.1c-1.5-1.3-3.1-2.4-4.6-3.4
		c-10.9,4.6-22.4,6.3-28.5,7C897.5-128,897.4-126.3,897.4-124.7z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M912.2-218.2c0,0-19.3,3-14.5,15.6c4.7,12.5,22.9,0.8,15.6-6.6C906-216.7,912.2-218.2,912.2-218.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M944-201.8c0,0-11.8-17.7-23.9-13c0,0-5.9,2.8-2.2,8.9c4.7,7.8,11.4,2.5,11.4,2.5S934.8-208.5,944-201.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M933.6-189.1c0,0-9.6,2.3-8.5,11.5c0,0,4.4,11.7,12,7.8c0,0,10.7-7.2,11.9-1
		C949-170.9,950.2-190.9,933.6-189.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M934.8-163.6c-11.7-5.9-15.5,2-17.6,10.5c3.2,0.4,9.1,1.7,15.5,6.4C939.1-153,942-160,934.8-163.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M914.4-141.7c2.5,0.6,5,1.5,7.5,2.7c4-2.1,7.8-4.9,10.9-7.8c-6.5-4.7-12.3-6-15.5-6.4
		c-0.7,2.7-1.2,5.4-1.7,7.8C915.2-143.9,914.8-142.7,914.4-141.7z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M907.2-135.3c3.8,0.8,9.3-0.8,14.7-3.6c-2.5-1.2-5-2.1-7.5-2.7C911.7-135.4,907.2-135.3,907.2-135.3z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M863.7-141.8c1.7,4.3,5.3,5.1,8.6,4.7c-2.9-2.2-5.9-3.9-8.8-5.3C863.6-142.2,863.7-142,863.7-141.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M876.2-144.4c0.3-9.5-15.4-6.9-12.6,2c3,1.4,5.9,3.1,8.8,5.3c3.8-0.5,7.3-2.5,7.3-2.5S876-137.9,876.2-144.4z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M887-145.3c10.5-4,16-18.7,16-18.7s-4,7.8-15.4,7.7C876.2-156.3,876.5-141.3,887-145.3z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M834.2-146.4c5.6,2,8.5,3,9.9,0.9c0.3-0.5,0.5-0.9,0.5-1.4C840.6-147.1,837-146.8,834.2-146.4z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M830.2-153.8c-9-1.4-21.7,0.6-21.7,0.6c11.8,1.8,20,4.8,25.6,6.8c2.9-0.4,6.4-0.7,10.4-0.5
		C845-150.4,838.3-152.6,830.2-153.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M885.2-194.2c2.1-5.3,1.5-11.7-4.7-14.7c-12-5.9-14.5,9-14.5,9c3.3,0,5.5,0.5,7.1,1.4
		C878.8-199.5,882.9-197.6,885.2-194.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M875.9-190.6c2,0.5,3.9,1,5.9,1.5c1.4-1.4,2.6-3.2,3.3-5.1c-2.3-3.4-6.4-5.3-12.1-4.3
		C876.3-196.6,876.5-193.3,875.9-190.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M874.3-186.1c2.7,0.4,5.4-0.8,7.5-3c-1.9-0.5-3.9-1.1-5.9-1.5C875.4-188.1,874.3-186.1,874.3-186.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M783.5-175.2c0.7-5.2,0.7-9.6,5.3-13.1c0,0-14,5.1-15.4,13.5C772.1-166.3,782.9-170,783.5-175.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M794.9-176c3.3,0.4,8.1,0.6,9.7-6.6c1.6-7.1,5.9-7.5,5.9-7.5s-12.3-3.7-18.1,3.1
		C786.6-180.2,791.6-176.5,794.9-176z M794.5-184.8c2.4-4.6,9.4-3.4,9.4-3.4s-2.5,0.8-2.4,5.1c0,4.3-2.9,4.4-4.9,4.6
		S792.1-180.2,794.5-184.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M796.6-178.5c1.9-0.2,4.9-0.3,4.9-4.6c0-4.3,2.4-5.1,2.4-5.1s-6.9-1.3-9.4,3.4
		C792.1-180.2,794.6-178.3,796.6-178.5z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M834.4-174.8c14.4-1.7,19-10.7,19-10.7c-11,8.2-15.7,4.5-27.9-0.2c-12.3-4.7-13.6-1.3-13.2,4.8
		C812.7-174.7,820.1-173,834.4-174.8z M823.4-183.8c8.9,5.6,12.6,4.8,12.6,4.8s-11,3.1-16.7,0.9
		C813.5-180.2,814.5-189.4,823.4-183.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M819.2-178.1c5.7,2.1,16.7-0.9,16.7-0.9s-3.6,0.8-12.6-4.8C814.5-189.4,813.5-180.2,819.2-178.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M849.5-205.1c-7.4,2.4-5.7,7.4-2.4,9.9c3.7,0.3,7.3,0.7,10.9,1.2C858.3-197.8,858.2-207.9,849.5-205.1z
		 M855.3-198.3c0,0-0.7-1.7-3.4,0.8s-6.6-4.8-1.3-4.7C855.8-202.2,855.3-198.3,855.3-198.3z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M851.6-193.8c4.5-1,6.3,1.1,6.3,1.1s0.1-0.5,0.1-1.3c-3.6-0.5-7.2-0.9-10.9-1.2
		C848.5-194.2,850.2-193.6,851.6-193.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M850.6-202.3c-5.2-0.1-1.4,7.2,1.3,4.7s3.4-0.8,3.4-0.8S855.8-202.2,850.6-202.3z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M983.2-123.8c-2.3-5.9-11-6.6-11-6.6c2.8,3.5,3.6,6.4,3.4,8.7l8.1-0.2C983.6-122.6,983.5-123.3,983.2-123.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M972.2-115.6c11,10.1,13,0,11.6-6.4l-8.1,0.2C975.4-117.7,972.2-115.6,972.2-115.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M754.9,53.1c-7,17.6,35.5,15.2,43.8,3.9c0,0-5.2,4.3-14.3-2.4C775.2,47.8,761.9,35.4,754.9,53.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M773-29.2c-22.4-1.4-15.5,24.3-15.5,24.3c5.8,40.3-10.2,48-10.2,48C785.3,31.9,795.4-27.9,773-29.2z
		 M770.7-24.2c12.2,0.2,9.2,16.9,9.2,16.9s0.3-4.8-6.9-1.9C762-4.7,758.6-24.4,770.7-24.2z M761.8,29.3c0,0,7.2-7.8,2.4-20.6
		c-4.8-12.7,12.5-18.8,12.3-5.3C776.3,16.9,761.8,29.3,761.8,29.3z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M773-9.2c7.3-2.9,6.9,1.9,6.9,1.9s3-16.6-9.2-16.9C758.6-24.4,762-4.7,773-9.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M763.1-52.8c-7.3-7.9-35.7-27.9-28.7-96c0,0-20.6,45.4,2.3,90.1C759.7-14,773.3-41.7,763.1-52.8z M758.3-44.7
		c-3.7,4.8-9.9-2.4-15.2-12c-5.3-9.6-8.3-24.6-8.3-24.6C748.7-56.4,762.1-49.5,758.3-44.7z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M743.1-56.7c5.3,9.6,11.5,16.8,15.2,12s-9.6-11.7-23.5-36.5C734.9-81.2,737.8-66.2,743.1-56.7z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M816.1-134.4c-15.6,5.2-25.2,33-25.2,33c23-32.5,37.6-12.4,37.6-12.4C845-126.5,833-140,816.1-134.4z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M699.6-73.3c7.5-4.8,5.3-10.2,3.5-13.6c-1.9-3.5-8.3-7.5-13.6,1.9c-5.3,9.3,4,21.6,4,21.6
		S692.2-68.5,699.6-73.3z M692.9-71.7c0,0-4.3-7.2,0-11.9c4.3-4.7,7.5-2.6,8.1-0.4s1.7,5.6-3.2,7.4
		C692.8-74.8,692.9-71.7,692.9-71.7z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M716.7-17.2c0,0,8.3-9,4.3-25.9c-4-16.8-9-24.4-16.1-22.2s-10.5,5.1,0,16.9C715.4-36.7,721.5-33,716.7-17.2z
		 M710.8-58.7c4.8,5.5,5.9,19.1,5.9,19.1S716.1-44,706-51.7C695.9-59.3,706-64.2,710.8-58.7z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M706.3-15.8c-0.8-5.5-14.3-11-13.7,2.4c0.5,13.4,17.6,4.2,17.6,4.2S707.1-10.3,706.3-15.8z M696.2-13.4
		c-2.2-5.8,7.7-4.6,6-0.5c-1.7,4.1,0.5,4.2,0.5,4.2S698.5-7.5,696.2-13.4z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M702.2-13.9c1.7-4.1-8.2-5.3-6,0.5c2.2,5.8,6.5,3.6,6.5,3.6S700.5-9.9,702.2-13.9z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M838.2-49.2c-5.4-10.9-12.8,0.4-10.1,6.1c0.1,0.2,0.1,0.3,0.2,0.5c2.5,2.1,4.9,4,7.2,5.8
		C838.6-39.6,840.9-43.7,838.2-49.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M827.5-32.2c0.3-0.1,4.6-1.6,8-4.6c-2.3-1.8-4.8-3.8-7.2-5.8C830.5-37.2,827.5-32.2,827.5-32.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M778.1-60.2c0,0,12,16.2,23.7,20.7s19.5,8.7,20.3,1.7c0.8-7.1-3.1-9.1-19.3-10.8
		C786.7-50.4,778.1-60.2,778.1-60.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M840.9,102.2c1.2,0,2.5-0.1,3.9-0.4c-1-2.9-2.2-6-3.8-9.3c-1.4,0.8-3.2,1.3-5.4,1.4c1.1,2.4,2.1,5.1,3,8.1
		C839.3,102.1,840.1,102.2,840.9,102.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M828.8,92.7c0,0,1.3,2.8,3.8,5.4c-0.1-1.6-0.3-3.1-0.5-4.6C831.1,93.3,830,93,828.8,92.7z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M940.6,24.4c0,0-2.1,0.2-4.4,1.6c1.8,1.3,3.6,2.5,5.3,3.8c6.1-2.5,14.5,0.5,14.5,0.5s-5.5-1.4-3.8,7.4
		c1.4,1,2.7,2.1,4.1,3.1c1.8-3.1,5.3-3,5.3-3c8.7,1,12.6,11.8,14.3,19.1c1.1,0.9,2.1,1.9,3.2,2.8C976.4,43,967.8,21.1,940.6,24.4z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M977,63.8c-5.2-11.7-12.5-9.3-12.5-9.3s-8.6,2-9.2-8.5c-0.1-2.3,0.3-3.9,1-5c-1.3-1.1-2.7-2.1-4.1-3.1
		c0.1,0.7,0.3,1.5,0.5,2.3c3.1,11.4-18.9,14.1-17.3-1.3c0.5-5.1,3-7.8,6.1-9c-1.8-1.3-3.5-2.6-5.3-3.8c-1.6,1-3.2,2.5-4.3,4.9
		c-1.7,3.6-4.3,8.9,1.9,17.9c14.1,20.7,29.7-7.3,46.4,29.6c0,0,0.6-8.4-1-18.5c-1.1-0.9-2.1-1.9-3.2-2.8
		C976.8,60.9,977,63.8,977,63.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M896.4,112c0,0,50.5,26.6,71.3-7.9S957.4,54,943.9,71.3C930.3,88.7,917.5,111.5,896.4,112z M948.3,78.9
		c3.4-10,13.2-7.4,13.2-7.4c16,6.7,5.3,26.7,5.3,26.7c1.8-6.9-11.3-5.1-11.3-5.1C946.7,93.2,948.3,78.9,948.3,78.9z M924.4,107.1
		c6.7-9.1,15.9-24.7,26.1-8.8c10.2,15.9-28.5,24-38.5,14.8C912.1,113.1,917.7,116.1,924.4,107.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M935.1,69.4c0,0-8.2,14.2-41.7,4.2c0,0-21.1-9.4-22.2,11.8C870.1,106.7,921.8,103.9,935.1,69.4z M889.1,94.5
		c0,0-14.4,0.9-13.3-10.6c1.1-11.5,17.4-6,12.5,3.8C885,94.2,889.1,94.5,889.1,94.5z M898.5,92.4c-11.4-1.3-4.5-17,5.8-11
		s17.7,0.1,17.7,0.1S909.9,93.7,898.5,92.4z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M825.5,35.4c-5.4-1.7-10.7-2.7-15.7-3.2c0,0.5,0.1,0.7,0.1,0.7c-0.1,2.7-0.2,5.2-0.5,7.7
		c5.7,1.7,10.6,3.8,14.9,6C826.1,40.2,826.4,41.8,825.5,35.4z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M852.3,73.4c8.3,10.9,33.2,1.2-2.3-25.5c-6.3-4.7-12.7-8.1-19.1-10.6c-2.2,4.9-5.3,8.4-6.4,9.5
		C842.1,56.2,848.6,68.7,852.3,73.4z M851,54.2c7.6,6.1,13,12.7,8.6,15.7c-4.5,3-8.9-10.4-28.6-26.3C831.1,43.6,843.5,48.1,851,54.2
		z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M809.8,32.2c-2.4-0.3-4.7-0.4-6.9-0.5c0,2.3,0,4.7-0.1,7.1c2.3,0.5,4.5,1.1,6.7,1.8c0.2-2.5,0.4-5,0.5-7.7
		C809.8,33,809.8,32.7,809.8,32.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M773.9,35.5c11.1,0.2,20.6,1.4,28.8,3.3c0.1-2.4,0.2-4.8,0.1-7.1C786,31.2,773.9,35.5,773.9,35.5z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M824.2,46.7c0.1,0,0.2,0.1,0.3,0.1c1.1-1.2,4.2-4.6,6.4-9.5c-1.8-0.7-3.6-1.3-5.4-1.9
		C826.4,41.8,826.1,40.2,824.2,46.7z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M811.4,15.2c5,0,9.1,0.5,12.6,1.4c0.9-6.3,5.4-2.9,7.8,2.9c8.7,4.4,14.9,11.4,28.8,18
		c22.3,10.5,35.4-0.6,41.7-12.7c6-11.4-10.7-27.3-18.7-18.6c0.3,2.5-0.7,4.8-3.4,6.6c-2.6,9.7,5.2,15.1,5.2,15.1s-5.2,8-20-10.1
		C857.7,8.4,851.9,1.4,845.1-3c-0.6,1.8-1.5,3.1-2.6,4c4.5,2.1,7.9,5.7,7.9,5.7s-4.2-1.8-9,4.7s-9.1,3.9-11.9,1.7
		c-2.8-2.1-5.5-8.6,3-12.6c0.7-0.3,1.4-0.6,2.1-0.7c0.4-1.5,0.6-4.2-1.6-7.6c-3.5-0.7-7.4-0.9-12-0.6c-6.5,0.4-13.1,3.5-19.2,7.6
		c2.2,6,3.8,11.5,5,16.2C808.3,15.3,809.8,15.2,811.4,15.2z M892.9,11.4c11.3,2,1.7,17,1.7,17s-0.6-3-5.2-4.4
		C884.8,22.7,881.6,9.4,892.9,11.4z M847.6,17.2c2.6-6.5,5.4-9.3,14.3,1.9c8.9,11.2,11.4,17.4,25.3,14.7c0,0-8.5,6.8-22.3,1.1
		C850.9,29.2,845,23.6,847.6,17.2z M809.4,3.2c5.2-8,20.4-6,20.4-6c-5.9,1.3-8,5.8-11,10.8C815.7,13,804.2,11.2,809.4,3.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M776.9,24.2c0,0,2.3-2,7.3-4.1c3.2-4.3,6-8.7,8.2-13.2C783.2,15.4,776.9,24.2,776.9,24.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M806.8,15.4c-1.2-4.7-2.8-10.2-5-16.2c-0.8,0.6-1.6,1.1-2.4,1.7c0.8,4.1,1.7,9.2,2.3,14.9
		C803.4,15.6,805.1,15.5,806.8,15.4z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M799.5,0.9c-2.5,1.9-4.9,4-7.2,6c-2.2,4.5-5,8.9-8.2,13.2c4-1.7,9.9-3.5,17.7-4.3
		C801.1,10,800.3,4.9,799.5,0.9z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M834.6-0.3c2.7-0.6,5.5,0.1,7.9,1.2c1.1-0.9,2-2.1,2.6-4c-3.6-2.3-7.5-4-12.1-4.8
		C835.1-4.5,834.9-1.7,834.6-0.3z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M824,16.6c2.9,0.7,5.5,1.7,7.8,2.9C829.3,13.7,824.9,10.3,824,16.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M833.3,3.8c-4.4,3.5-3,6.7-1.1,7.6c1.8,0.8,4.6,2.2,6.6-2.2c2-4.4,4.6-4,4.6-4S837.7,0.3,833.3,3.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M868.2,31.2c0,0-3.7-1-9.1-11.4c-5.4-10.4-10.6-1.4-6.5,3.7C856.7,28.7,868.2,31.2,868.2,31.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M891.4,20.5c3.6-1.1,3.5,1,3.5,1s2.3-3.8-2.4-6.5"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M858.9,130.8c1.9,0,3-0.8,3.4-2.2c-3.5-1.9-6.8-3.6-10.1-5.2C853,129,854.9,130.7,858.9,130.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M844.5,86.7c0,0,0.3,0.4,0.8,1.1c0-0.1,0-0.1,0.1-0.2C844.8,87.1,844.5,86.7,844.5,86.7z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M850.3,99.7c0.9,3.3,1.4,7.3,1.4,11.7c-0.1,5.2,0,9,0.4,11.9c3.3,1.6,6.7,3.4,10.1,5.2c0.9-3.1-1-9.2-2.7-17.1
		c-1.1-4.7-3.8-9.7-6.6-13.9C852.3,98.4,851.4,99.1,850.3,99.7z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M853,97.5c-3-4.6-6.2-8.3-7.7-9.9c0,0.1,0,0.1-0.1,0.2c1.2,1.8,3.6,5.9,5.1,11.9
		C851.4,99.1,852.3,98.4,853,97.5z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M911.1,139.6c-17.3-4.5-7,8.6,2.4,9.2s17.4-6.6,17.4-6.6C922.2,143.8,928.4,144.1,911.1,139.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M946.7,146c-6.8,1.8-5.6,7.4-14,9.7c0,0,11.6,2.9,16.7,0.2C954.6,153.2,953.5,144.2,946.7,146z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M1030.7,419.7c-1.3,1.1-2.7,2.2-4,3.3c-0.8,6.3-3.9,15.2-15.5,14.3c0,0-1.4-0.3-3.1-1.2
		c-2.1,1.3-4.2,2.5-6.3,3.8C1012.7,447.9,1031,443.4,1030.7,419.7z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M1001.7,425.1c0,0,4.2-12.9,12.5-8.8c0,0,11.6,7.4,12.6,0.7c0,0,0.3,2.6-0.2,6c1.4-1.1,2.7-2.2,4-3.3
		c0-2.3-0.2-4.8-0.6-7.4c-5.9-38.5-64.1-38.3-64.1-38.3c19.7,9.8,22.7,34.8,28.6,55.5c1.3,4.5,3.9,8,7.2,10.4
		c2.1-1.2,4.2-2.5,6.3-3.8C1005.1,434.6,1001.2,431.4,1001.7,425.1z M990.4,390.6c-2.7-10.7-9.2-10.6-9.2-10.6
		c13.1-3.4,46.4,20.7,30.4,29.6C995.5,418.6,993.1,401.3,990.4,390.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M931.6,385.3c-7.5,18.5,16.2,19.9,16.2,19.9c35.7,6.4,37.7,22,37.7,22C986.6,392.1,939,366.8,931.6,385.3z
		 M960.1,391.5c11.3,4,17.7,19.7,17.7,19.7s-4.6-8.3-16.7-7.8C949.1,403.9,948.8,387.6,960.1,391.5z M952,385.6c0,0-4-1.6-3.5,5.3
		c0.7,10.5-16.9,7.9-13.3-2.3C938.8,378.4,952,385.6,952,385.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M908.9,387.1c-8.8,4-28.2,22.3-83.7-2.7c0,0,27.2,30.1,71.3,23.3C940.7,400.9,921.1,381.6,908.9,387.1z
		 M900.1,402.8c-9.6,1.8-23.1,0.1-23.1,0.1c24.9-4.7,34.4-14.1,37.4-9.6C917.5,397.9,909.7,401,900.1,402.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M840.5,427.6c-1.7-9.1,9.8-5.5,10.7,0.1s1.1,10.4,6.1,14c0,0-10-3.3-14.6-9.5c-1.4-0.9-7.1-4.6-14.3-10.2
		c4.4,8.2,10.3,16.2,17.9,20.4c3.4,1.7,7.1,3.3,10.9,4.7c14.1,4.3,24.7,0.1,41.9-7c15.8-6.5,20.8-3.1,22.3-1
		c0.8-1.1,2.1-1.8,3.3-0.7c2.9,2.6,3.7,0.7,3.7,0.7s0.7,4.2-4.9,4.5c-2.4,0.1-3.1-1.4-2.8-2.8c-2.8,0.7-9,3.3-9.7,11.1
		c5.3-1,10.7-2.4,16.3-4.3c0,0-5.6,5.2-15.6,10.6c4.1,10.8,26.1,4.8,25-7.6c-1.1-13.1-9.1-28.3-34.3-29.3s-32.4,7.9-55.3-3
		c-14.9-7-22.6-14.6-26.3-19c4.5,8.8,11.2,19.2,20.9,31.5C841.2,429.8,840.8,428.7,840.5,427.6z M882.1,432.7
		c0.2-6.6,7.9-8.7,23.3-7.3c15.3,1.3,20.6,10.9,20.6,10.9c-11.9-8.5-16.8-4.3-29.7,1.2C883.4,443,881.9,439.3,882.1,432.7z
		 M863.4,428.1c3.4-0.6,8.6-0.9,10.5,6.8s6.6,8,6.6,8s-13,4.4-19.4-2.7C854.7,433,859.9,428.7,863.4,428.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M873.4,441c0,0-2.6-0.8-2.8-5.4c-0.1-4.7-3.3-4.7-5.3-4.8c-2.1-0.1-4.7,2-2,6.9C866,442.6,873.4,441,873.4,441
		z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M893.9,435.6c9.3-6.4,13.2-5.6,13.2-5.6s-11.8-2.9-17.8-0.4C883.3,432,884.6,441.9,893.9,435.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M922.1,440.4c0,0-0.1-0.6-0.6-1.3c-0.3,0.5-0.6,1-0.7,1.6C921.5,440.5,922.1,440.4,922.1,440.4z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M920.7,440.7c-0.3,1.5,0.3,3,2.8,2.8c5.6-0.3,4.9-4.5,4.9-4.5s-0.7,1.9-3.7-0.7c-1.2-1-2.5-0.4-3.3,0.7
		c0.5,0.7,0.6,1.3,0.6,1.3S921.5,440.5,920.7,440.7z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M935.3,335c5.5-0.7,9,3.5,9,3.5c0-0.5-0.2-15.3-11.3-13.8C922.3,326.2,929.8,335.7,935.3,335z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M938.1,341.4c-5.7-2.7-8.5,0.1-14.5,13.2c-6,13.1-16.7,17.7-16.7,17.7s17-5.6,24.1-14.2
		C938.1,349.4,943.8,344.1,938.1,341.4z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M576.8,309.6c-36.6-17.8-48.9-4.9-48.9-4.9c13.3-19.6,40.2-24.4,58.5-20.4c-0.6-6.1-0.8-12.3-0.7-18.7
		c-16.2-12.4-29.1-17.3-29.1-17.3c-74.9-21.6-85.6,50.6-85.6,50.6c-6.4,53.7,42.1,65.8,57.9,48.7s-5.7-34.9-5.7-34.9
		c32.1-13.9,58.7,2.1,73.9,15.7c-1.8-4.6-3.5-9.3-4.9-14.1C584.6,314.7,576.8,309.6,576.8,309.6z M525.5,326.4
		c5.3,10.7,1.3,15.6-1.4,19c-4.4,5.6-11.4,4.8-11.4,4.8c-51.2-4.1-34.7-64.7-34.7-64.7C489.9,327.4,513.4,301.9,525.5,326.4z
		 M518.3,300.8c-19,15-51.8-8.1-21-39.2s83.7,7.6,83.7,7.6C556.5,265.1,537.3,285.9,518.3,300.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M610.9,343.4c19.3,21,38.9,31.3,56.8,35.5c1.7-2.1,3.6-4.1,5.8-5.9c-16.1-3.4-35.4-11-51.8-27.3
		c-35.7-35.4-5.1-40,2.4-26.9c4.5,8,12.8,33.1,56.3,49.1c2.1-1.3,4.4-2.5,6.8-3.5c-37.9-13-57.1-45.1-57.1-45.1
		c-12.7-25.3-29.9-42.5-44.4-53.7c-0.1,6.3,0.1,12.6,0.7,18.7c12.2,2.7,20.5,9.2,18.3,18c-2.2,8.9-7.3,11.8-12.5,12
		c1.4,4.8,3.1,9.5,4.9,14.1C605.9,336.3,610.9,343.4,610.9,343.4z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M501.9,331.2c1.4,12.5-5.7,9.4-5.7,9.4s20.4,11.3,25.4-4.3C526.5,320.8,500.5,318.7,501.9,331.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M500.1,324.6c1.4-10.9-8.9-10.8-8.9-10.8s-9,0.8-12.6-12.4c0,0-2.1,25.7,12.8,30.1
		C491.3,331.5,499,333.2,500.1,324.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M509.1,275.8c0,0-15.4-1-12-7.7c0,0-16.3,18.4,0.8,28.8c0,0,10.8,4.8,16.7-4.8
		C514.6,292.1,519.3,277.6,509.1,275.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M515.9,271.5c8.6,18.7,22.3,4.6,31.8-3.3c9.5-7.9,15.4-3.5,15.4-3.5C553.4,252.9,507.2,252.8,515.9,271.5z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M585.9,289c0,0,0.6,0.2,1.1,0.6c0-0.1,0-0.3,0-0.4C586.3,289,585.9,289,585.9,289z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M585.6,296.2c-4.5,5.4-0.1,10.9,4.9,12c-1.2-4.9-2.2-9.9-3-15C587.2,294,586.6,295,585.6,296.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M599.2,302.9c3-9.9-8.6-13-12.2-13.7c0,0.1,0,0.3,0,0.4c0.7,0.6,1.3,1.6,0.5,3.5c0.8,5.1,1.8,10.1,3,15
		C594,308.9,597.7,307.6,599.2,302.9z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M545.5,295.2c0,0,9.7-4.5,20.3,3.9c10.7,8.5,21.7-6.2,8.8-10.1C561.7,285.2,545.5,295.2,545.5,295.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M615,320.9c-5.7,2.1-0.7,10.1,6.8,18.1s20.9,15.3,20.9,15.3C623.2,333.6,620.7,318.8,615,320.9z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M551.8,354.9c-7.7-8.3-29.8,2.9-22.9,17.4c6.9,14.5,20.4,2,20.4,2C534,362.4,551.8,354.9,551.8,354.9z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M555.1,345.5c0,0,5.8-26.5,43-4c0,0-21.7-23.6-39.5-22C539.3,321.3,533.1,340.1,555.1,345.5z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M654.2,403.8c-8.3,2.3-19.3-1.2-19.3-1.2c5.2,0,8.4-2.4,11.6-5.5c-2-1.8-4-3.7-5.9-5.6c0,2.9-1.9,5.9-8,7.2
		c-10.5,2.3-19.4-10.4-19.4-10.4s4.4,2.8,11.3-2.9c2.9-2.4,5.3-3.1,7.4-2.9c-7.2-7.8-13.7-16.2-19.4-24.9c-1.5-1.1-3.1-2.3-4.9-3.5
		c-23.6-15.8-40.8-7.2-50.6,4c-9.7,11.2,9.1,33.1,19.8,21.7c10.7-11.4,0.2-21.2,0.2-21.2s7.6-7.1,21,14.9c13.5,22,20.7,33.4,45.9,37
		c3.2,0.4,6.5,0.4,9.9,0c0.4-1.9,0.8-3.9,1.3-6C654.8,404.3,654.5,404,654.2,403.8z M565.1,374.1c-12.6-4.6,1.4-18,1.4-18
		s0.1,3.2,5.1,5.7C576.6,364.2,577.7,378.7,565.1,374.1z M602.3,373c-8-13.6-9.7-20.5-26.2-20.7c0,0,11.1-5.2,25.9,3.8
		c14.8,8.9,20.5,15.9,16.3,22.1C614.1,384.2,610.3,386.6,602.3,373z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M638.4,386.4c1.2,1.2,2.1,3.2,2.1,5.2c1.9,1.9,3.9,3.7,5.9,5.6c1.1-1,2.1-2.1,3.2-3.3c2.2-2.2,6.5-2.2,9.4-0.8
		c0.7-1.6,1.5-3.1,2.3-4.7c-0.6-0.1-1.1-0.2-1.7-0.3c-25.9-4.9-28.5-16.2-47.4-30.4c5.7,8.8,12.2,17.1,19.4,24.9
		C634.6,382.8,636.8,384.7,638.4,386.4z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M654.2,403.8c0.3,0.2,0.6,0.5,0.9,0.7c0.1-0.4,0.2-0.7,0.3-1.1C655,403.6,654.6,403.7,654.2,403.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M634.9,402.6c0,0,11.1,3.5,19.3,1.2c-2.6-2.2-5.2-4.4-7.7-6.6C643.3,400.2,640.2,402.6,634.9,402.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M659.1,393c-2.8-1.4-7.1-1.4-9.4,0.8c-1.1,1.1-2.2,2.2-3.2,3.3c2.5,2.3,5.1,4.5,7.7,6.6
		c0.4-0.1,0.8-0.2,1.2-0.4C656.3,400.1,657.5,396.6,659.1,393z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M635,387.5c-1.9-1.3-4.8-3.3-8,0.8c-3.2,4.2-6.1,3.1-6.1,3.1s5.6,6.3,11.4,3.6
		C637.9,392.4,636.9,388.8,635,387.5z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M597.5,359.1c0,0,4.1,1.9,8.3,13.8c4.2,11.9,12,3.8,8.2-2.4C610.1,364.2,597.5,359.1,597.5,359.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M568.6,365.1c-4.4,0.4-3.8-1.8-3.8-1.8s-3.4,3.4,1.5,7.3C571.1,374.5,573,364.7,568.6,365.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M634.6,254.1c4.6,4.3,17.7,0.6,8.9-7.8c-9.1-8.8-19.1,4.5-19.4,5C624.1,251.3,630,249.8,634.6,254.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M632.3,279.7c3.3-15.9,2.5-20.3-4.4-21.6c-7-1.4-5.3,7.3-4.6,19.8c0.7,12.6,12.5,28.9,12.5,28.9
		S629,295.6,632.3,279.7z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M554.6,207.3c-22.2-15.3-41.8-4.3-45.2,4.7c-3.4,9-2.9,19.8,38.3,25.6c15.6,2.2,28.5,9.4,38.4,17.4
		c0.4-7.2,0.9-14.1,1.6-20.7C577.7,222.1,566.5,215.5,554.6,207.3z M521.5,210.3c12.7-5.8,27.4-4.4,40.4,8.7c0,0-5.6-3.1-23,5.9
		C521.5,233.9,508.8,216.1,521.5,210.3z M569.5,237.5c-19-8.4-12-6.8-22.2-7.1c0,0,10.6-5.7,21.3-3
		C579.3,230.1,588.5,245.9,569.5,237.5z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M610.6,283.7c-6.6-23.8-14.3-38.6-22.9-49.3c-0.7,6.6-1.2,13.5-1.6,20.7C602.3,268.2,610.6,283.7,610.6,283.7z
		"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M472.5,331.7c0,0-7.2,19.1-0.8,26.9c6.4,7.8,14.1,4.3,17.2-7.1C488.9,351.5,473.6,357.2,472.5,331.7z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M505.5,212c0-15.2,11.2-14.2,11.2-14.2c-3.1-19.7-14.3-7.5-16.6-0.2C497.8,205,505.5,212,505.5,212z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M528.6,212.1c-6.5,1.7-6.9,11.2,1.2,10.9c8.2-0.4,7.9-6.4,18-7C547.8,216,535,210.4,528.6,212.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M657.3,409.9c-0.1,0-0.2,0-0.2,0c-0.4,5.6-3.5,61.8,45.1,57.5c0,0,7-0.4,10.5-6.7c2.1-3.7,5.2-9.2-1.8-18.9
		c-1.4-1.9-2.8-3.5-4.3-4.7c-2.7-1.3-5.4-2.7-8.1-4.1C685.5,429.9,671.4,439,657.3,409.9z M688.3,450.5
		c-3.4-12.1,22.5-14.4,20.2,1.8c-2.3,16.1-24.3,8.4-24.3,8.4S691.7,462.6,688.3,450.5z M674.8,435.1c0,0,10.2-1.8,10.6,9.2
		c0.3,8.6-7.5,8.3-7.5,8.3c-15.4-1.9-17.6-27.6-17.6-27.6C666.1,437.4,674.8,435.1,674.8,435.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M698.5,433c2.7,1.4,5.4,2.8,8.1,4.1C703.9,434.9,701.2,433.7,698.5,433z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M657.1,409.5c0,0,0,0.2,0,0.4c0.1,0,0.2,0,0.2,0C657.3,409.7,657.2,409.6,657.1,409.5z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M672.6,389.8c0.1,0,0.2,0,0.3,0c0-0.2,0.1-0.5,0.2-0.8C673,389.1,672.8,389.3,672.6,389.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M678.6,417.3c0,0,11.5,3,15.6-7.5c0,0,1.7-11.7-5-14.6c-5.6,3.7-12.8,7.9-20.4,11
		C669.6,410.6,672.2,414.8,678.6,417.3z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M672.8,389.8c-0.1,0-0.2,0-0.3,0c-1.3,2.3-4.9,9.6-3.7,16.5c7.6-3.1,14.8-7.3,20.4-11
		c-0.9-0.4-1.9-0.6-3.2-0.6C686.1,394.7,671.8,396.2,672.8,389.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M691.9,389.1c6.2-1.1,9.3-2.6,9.3-2.6s-2.7,2.3-7.1,5.5c10.9,12.5,21.2-2.4,28.8-11.2c8.1-9.4,14.6-6,14.6-6
		c-1.5-1.3-3.6-2.3-6-3c-7.9,3.8-22.6,9.4-40.8,9.7C689.8,383.7,690.1,386.2,691.9,389.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M696.2,375.7c-2.7,1.6-4.6,3.5-5.5,5.7c18.2-0.4,32.9-5.9,40.8-9.7c-8.8-2.6-22.3-1.6-31.5,2.1
		c2.2,0.5,4.5,1.1,6.9,1.6C706.9,375.4,702.8,375.8,696.2,375.7z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M706.9,375.4c-2.4-0.5-4.6-1-6.9-1.6c-1.4,0.6-2.7,1.2-3.8,1.9C702.8,375.8,706.9,375.4,706.9,375.4z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M691.9,389.1c0.1,0.1,0.1,0.2,0.1,0.2c0.7,1,1.3,1.8,2,2.6c4.4-3.2,7.1-5.5,7.1-5.5S698.1,388,691.9,389.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M779.5,406.3c1.5-12.1-15.4-11.5-15.4-11.5s4.8,0.4,0.9,7.1C759,412.3,778,418.4,779.5,406.3z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M746,408.2c11.9,6.6,20.4-9.7,7-11.4s-27.7,10.9-27.7,10.9S734.1,401.7,746,408.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M807.8,438.2c8.7,6.6,23.2,11.6,23.2,11.6c-22.7-17.2-27.6-31.4-32.9-28.4
		C792.8,424.5,799.1,431.6,807.8,438.2z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M800.2,357.4c5.7,0.2,7.7,2.8,8.1,12.1c1.6,1.4,3.2,2.6,4.8,3.8c-0.1-17.6,15.2-20.1,15.2-20.1
		c2.9-6.9-26.1-37.4-39.2-1.7c-2.6,7.2-2.4,14.4-0.4,21.6c3.4,1.1,6.7,2.1,9.9,3C796,364.7,793.4,357.2,800.2,357.4z M813.7,343.1
		c10,6.8-2.2,12.6-7.5,9.2c-5.3-3.4-10.8-1-10.8-1C795.6,350.8,803.2,335.9,813.7,343.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M808.2,377.9c0,0.1,0,0.3,0,0.4c0.7,0.1,1.4,0.3,2.1,0.4c-0.7-1-1.4-2-2-2.9
		C808.3,376.5,808.3,377.2,808.2,377.9z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M828.4,422c-7-13-10.2-26.6-10.2-26.6s0.8,1.5,2.7,3.9c-2.8-5.5-4.8-10.4-6-14.7c-1.7-2-3.2-3.9-4.6-5.8
		c-0.7-0.1-1.4-0.3-2.1-0.4c-0.4,16,8,25.8,8,25.8s-14.3-14.2-17.1-26.4c-0.1-0.5-0.3-1.1-0.4-1.6c-3.2-0.9-6.6-1.9-9.9-3
		C794.4,392.7,814.4,411,828.4,422z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M808.3,375.8c0.6,1,1.3,1.9,2,2.9c1.1,0.2,2.2,0.4,3.4,0.6c-0.4-2.2-0.6-4.2-0.6-6c-1.6-1.2-3.2-2.5-4.8-3.8
		C808.3,371.3,808.3,373.4,808.3,375.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M814.9,384.5c-0.5-1.8-0.9-3.5-1.2-5.2c-1.1-0.2-2.2-0.4-3.4-0.6C811.7,380.6,813.2,382.5,814.9,384.5z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M828.4,422c7.2,5.7,12.9,9.3,14.3,10.2c-0.4-0.5-0.7-0.9-1-1.4c-9.7-12.4-16.4-22.8-20.9-31.5
		c-2-2.4-2.7-3.9-2.7-3.9S821.4,409,828.4,422z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M841.8,430.8c0.3,0.5,0.6,1,1,1.4c0.2,0.1,0.3,0.2,0.3,0.2C842.6,431.9,842.2,431.3,841.8,430.8z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M773.2,329.6c-12.8-1.3-11.7,10.7-9.1,10.2c2.6-0.4,12.7-1,18.6,25.3C782.7,365.1,786,331,773.2,329.6z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M675.6,333.4c-1.8,9,0.4,19.1,39.5,17.9c39.1-1.2,65.5,33.2,65.5,33.2c-21.1-46-40.8-52.1-63.8-62.8
		C693.8,310.9,677.3,324.4,675.6,333.4z M736.7,337c11,0.9,22.8,14.9,2.6,9.8c-20.2-5.1-12.9-4.7-23.1-3.2
		C716.2,343.6,725.7,336.1,736.7,337z M687.4,328.1c11.5-7.8,26.3-8.9,41.3,1.8c0,0-6-2.1-21.7,9.7
		C691.4,351.3,675.9,335.9,687.4,328.1z"
      />
      <SVNamePath
        className="SVNameSVG1"
        d="M697.7,339.2c8-1.7,6.7-7.6,16.6-9.9c0,0-13.5-3.3-19.6-0.6C688.6,331.4,689.7,340.9,697.7,339.2z"
      />
    </SVnamePaths>
  </svg>
);
