import React, { Component } from 'react';
import { connect } from 'react-redux';

import logoSV from '../../img/logoSV.png';
import logoSV2 from '../../img/logoSV2.png';
import fractalVideo from '../../video/fractal3.mp4';

import * as poseComponents from './components/poseComponents';

import * as actions from './services/HomeActions';
import * as navActions from '../../components/nav/services/navActions';

class Home extends Component {
  componentDidMount() {
    this.props.updatePageLoad(true, true);
  }

  componentWillUnmount() {
    this.props.updatePageLoad(false, false);
  }

  render() {
    return (
      <div className="view containerScale" style={{ height: '100vh' }}>
        <div className="full-bg-img content-wrap">
          <div className="content flex-center">
            <ul className="movedown">
              <div className="container-fluid">
                <poseComponents.HeroLogoDiv
                  pose={this.props.pageLoaded ? 'active' : 'hidden'}
                >
                  <div className="row">
                    <div className="col-md-12 flex-center">
                      <img
                        className="img-responsive homelogo flex-center"
                        src={logoSV && logoSV2}
                        width="315px"
                        height="211px"
                        alt="logo"
                      />
                      <div className="wrap heightwrap">
                        <svg viewBox="0 0 1000 300" className="svg-defs">
                          <symbol id="s-text2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="30%"
                              y="-25%"
                              width="700"
                              height="800"
                              viewBox="0 0 1000 800"
                            >
                              <path d="M468.896,118.65l-80.667,254.289H145.563l-24.334-62.667h188 c0,0,26.167,0.167,33.311-18.676l15.356-50.99c20.333-62.333,22.67-67.217-34-67.667c-15.121-0.12-30.689,0.122-39.254,0.072 c-17.878-0.105-22.246,0.139-20.368-10.094l10.712-34.979c0,0,1.285-9.287,17.66-9.287" />
                              <path d="M275.563,267.9c28.667,0,28.013-1.13,35.833-28.5c8-28,9.457-29.082-12-29.5 c-10.548-0.205-26.166,0-26.166,0c-51.959,0-64.834,1.5-58.222-24.03c2.682-10.354,6.587-25.693,12.221-45.637 c4.024-14.247,8.103-29.353,11.369-38.006C250.481,70.74,271.842,75.708,343.23,75.9c92.334,0.249,139.333,0,139.333,0l20.333-62 h-314l-41.553,149.031c0,0-2.537,10.65-6.408-0.212L87.896,13.9h-69l89.667,254H275.563" />
                            </svg>
                          </symbol>
                          <mask
                            id="m-text2"
                            maskUnits="userSpaceOnUse"
                            maskContentUnits="userSpaceOnUse"
                          >
                            <rect
                              width="100%"
                              height="100%"
                              className="mask__shape"
                            />
                            <use href="#s-text2" className="mask__text" />
                          </mask>
                        </svg>

                        <div className="box-with-text">
                          <div className="text-fill">
                            <video
                              className="video"
                              src={fractalVideo}
                              autoPlay="autoplay"
                              muted
                              loop
                            />
                          </div>
                          <svg
                            viewBox="0 0 1000 300"
                            className="svg-inverted-mask"
                          >
                            <rect
                              width="100%"
                              height="100%"
                              mask="url(#m-text2)"
                              className="shape--fill"
                            />
                            <use
                              href="#s-text2"
                              className="text--transparent"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </poseComponents.HeroLogoDiv>
                <poseComponents.HeroNameDiv
                  pose={this.props.pageLoaded ? 'active' : 'hidden'}
                >
                  <div className="row">
                    <div className="col-md-12 flex-center">
                      <div className="wrap">
                        <svg viewBox="0 0 1000 300" className="svg-defs">
                          <symbol id="s-text">
                            <text
                              textAnchor="middle"
                              x="50%"
                              y="50%"
                              dy=".35em"
                              className="text"
                            >
                              Scott Vance Jr.
                            </text>
                          </symbol>

                          <mask
                            id="m-text"
                            maskUnits="userSpaceOnUse"
                            maskContentUnits="userSpaceOnUse"
                          >
                            <rect
                              width="100%"
                              height="100%"
                              className="mask__shape"
                            />
                            <use href="#s-text" className="mask__text" />
                          </mask>
                        </svg>

                        <div className="box-with-text">
                          {/* <!-- Container for video --> */}
                          <div className="text-fill">
                            <video
                              className="video"
                              src={fractalVideo}
                              autoPlay="autoplay"
                              muted
                              loop
                            />
                          </div>

                          {/* <!-- Visible SVG --> */}
                          <svg
                            viewBox="0 0 1000 300"
                            className="svg-inverted-mask"
                          >
                            <rect
                              width="100%"
                              height="100%"
                              mask="url(#m-text)"
                              className="shape--fill"
                            />
                            <use href="#s-text" className="text--transparent" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mobilehead">
                    <h1 className="h1-responsive homeheading">Scott Vance</h1>
                    <div
                      style={{
                        display: 'flex',
                        textAlign: 'center',
                        margin: '.75rem'
                      }}
                    >
                      <div
                        className="homebutton-mobile button btn-lg"
                        style={{ padding: '.75rem 0', flexGrow: 1 }}
                        onClick={() =>
                          this.props.changeRoute(this.props.history, '/about')
                        }
                      >
                        About
                      </div>
                      <div
                        style={{
                          flexGrow: 1,
                          borderBottom: '1px solid #ffb96c',
                          margin: '1.5rem 0'
                        }}
                      />
                      <div
                        className="homebutton-mobile btn-lg"
                        style={{ padding: '.75rem 0', flexGrow: 1 }}
                        onClick={() =>
                          this.props.changeRoute(this.props.history, '/works')
                        }
                      >
                        Works
                      </div>
                    </div>
                  </div>
                </poseComponents.HeroNameDiv>
              </div>
              <div className="container-fluid">
                <poseComponents.MiddleContentDiv
                  pose={this.props.pageLoaded ? 'active' : 'hidden'}
                >
                  <div className="row movedown2">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <li>
                        <div
                          onClick={() =>
                            this.props.changeRoute(this.props.history, '/about')
                          }
                          className="btn-lg homebutton1 cmn-t-underline flex-center"
                        >
                          ABOUT
                        </div>
                      </li>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <li>
                        <h4 className="h4-responsive baseline flex-center">
                          WEB DEVELOPER
                        </h4>
                        <h4 className="h4-responsive hometagline flex-center">
                          Frontend, UX, Graphic Design
                        </h4>
                      </li>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <li>
                        <div
                          className="btn-lg homebutton2 cmn-t-underline flex-center"
                          onClick={() =>
                            this.props.changeRoute(this.props.history, '/works')
                          }
                        >
                          WORKS
                        </div>
                      </li>
                    </div>
                  </div>
                </poseComponents.MiddleContentDiv>
                <poseComponents.ContactButtonDiv
                  pose={this.props.pageLoaded ? 'active' : 'hidden'}
                >
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 flex-center">
                      <button
                        type="button"
                        className="thebutton contactbtn"
                        onClick={() => {
                          this.props.updateColumn('contactModalToggle', true);
                        }}
                      >
                        CONTACT
                      </button>
                    </div>
                  </div>
                </poseComponents.ContactButtonDiv>
              </div>
            </ul>
          </div>
        </div>
        <poseComponents.ContactModalOverlay
          className="overlay overlay-contentscale flex-center"
          pose={this.props.contactModalToggle ? 'active' : 'hidden'}
        >
          <button
            type="button"
            className="overlay-close"
            onClick={() => {
              this.props.updateColumn('contactModalToggle', false);
            }}
          >
            Close
          </button>
          <nav>
            <h4>Got a project in mind?</h4>
            <hr />
            <span className="contactinfo">
              <div style={{ display: 'block' }}>Contact me by e-mail:</div>
              <a
                href="mailto:scottvance1234@gmail.com"
                style={{ paddingTop: '.5em', display: 'block' }}
              >
                <span className="email">scottvance1234@gmail.com</span>
              </a>
            </span>
          </nav>
        </poseComponents.ContactModalOverlay>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pageLoaded: state.nav.pageLoaded,
    contactModalToggle: state.home.contactModalToggle
  };
};

export default connect(
  mapStateToProps,
  { ...actions, ...navActions }
)(Home);
