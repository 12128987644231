export const caseStudyLightBox = (
  caseStudyIndex,
  toggle,
  lightBoxIndex,
  photoIndex
) => dispatch => {
  dispatch({
    type: 'WORKS_CASE_LIGHTBOX',
    payload: {
      caseStudyIndex,
      toggle,
      lightBoxIndex,
      photoIndex
    }
  });
};
