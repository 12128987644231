import update from 'immutability-helper';

export default (
  state = {
    pageLoaded: false,
    logo: false,
    logoHoverValue: false,
    mobileNav: {
      toggle: false
    }
  },
  action
) => {
  switch (action.type) {
    case 'NAV_MOBILE_TOGGLE':
      state = update(state, {
        mobileNav: {
          toggle: {
            $set: action.payload
          }
        }
      });
      break;

    case 'NAV_UPDATE_PAGE_LOAD':
      state = update(state, {
        pageLoaded: {
          $set: action.payload.pageLoaded
        },
        logo: {
          $set: action.payload.logo
        }
      });
      break;

    case 'NAV_LOGO_HOVER':
      state = update(state, {
        logoHoverValue: {
          $set: action.payload
        }
      });
      break;

    default:
      break;
  }
  return state;
};
