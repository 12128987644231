import * as THREE from 'three';
import { TweenLite } from 'gsap';

export default opts => {
	let vertex = `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
    `;

	let fragment = `
        
        varying vec2 vUv;

        uniform sampler2D currentImage;
        uniform sampler2D nextImage;

        uniform float dispFactor;

        void main() {

            vec2 uv = vUv;
            vec4 _currentImage;
            vec4 _nextImage;
            float intensity = 0.3;

            vec4 orig1 = texture2D(currentImage, uv);
            vec4 orig2 = texture2D(nextImage, uv);
            
            _currentImage = texture2D(currentImage, vec2(uv.x, uv.y + dispFactor * (orig2 * intensity)));

            _nextImage = texture2D(nextImage, vec2(uv.x, uv.y + (1.0 - dispFactor) * (orig1 * intensity)));

            vec4 finalTexture = mix(_currentImage, _nextImage, dispFactor);

            gl_FragColor = finalTexture;

        }
    `;

	let images = opts.images,
		image,
		sliderImages = [];
	let parent = opts.parent;
	let canvasWidth = parent.clientWidth;
	let canvasHeight = parent.clientHeight;
	let renderWidth = Math.max(
		document.documentElement.clientWidth,
		window.innerWidth || 0
	);
	let renderHeight = Math.max(
		document.documentElement.clientHeight,
		window.innerHeight || 0
	);

	let renderW, renderH;

	if (renderWidth > canvasWidth) {
		renderW = renderWidth;
	} else {
		renderW = canvasWidth;
	}

	renderH = canvasHeight;

	let renderer = new THREE.WebGLRenderer({
		antialias: false
	});

	renderer.setPixelRatio(window.devicePixelRatio);
	renderer.setClearColor(0x23272a, 1.0);
	renderer.setSize(renderW, renderH);
	parent.appendChild(renderer.domElement);

	let loader = new THREE.TextureLoader();
	loader.crossOrigin = 'anonymous';

	images.forEach(img => {
		image = loader.load(img.backgroundImg + '?v=' + Date.now());
		image.magFilter = image.minFilter = THREE.LinearFilter;
		image.anisotropy = renderer.capabilities.getMaxAnisotropy();
		sliderImages.push(image);
	});

	let scene = new THREE.Scene();
	scene.background = new THREE.Color(0x23272a);
	let camera = new THREE.OrthographicCamera(
		renderWidth / -2,
		renderWidth / 2,
		renderHeight / 2,
		renderHeight / -2,
		1,
		1000
	);

	camera.position.z = 1;

	let mat = new THREE.ShaderMaterial({
		uniforms: {
			dispFactor: { type: 'f', value: 0.0 },
			currentImage: { type: 't', value: sliderImages[0] },
			nextImage: { type: 't', value: sliderImages[1] }
		},
		vertexShader: vertex,
		fragmentShader: fragment,
		transparent: true,
		opacity: 1.0
	});

	let geometry = new THREE.PlaneBufferGeometry(
		parent.offsetWidth,
		parent.offsetHeight,
		1
	);
	let object = new THREE.Mesh(geometry, mat);
	object.position.set(0, 0, 0);
	scene.add(object);

	let addEvents = function() {
		let activeIndex = 0;
		let nextWorkIndex;
		let workLength = sliderImages.length;
		let upButton = document
			.getElementById('pagination')
			.querySelector('div.workPrevious');
		let downButton = document
			.getElementById('pagination')
			.querySelector('div.workNext');
		let mainScreen = document.getElementById('mainScreen');

		let isAnimating = false;

		downButton.addEventListener('click', function() {
			if (!isAnimating) {
				isAnimating = true;

				if (activeIndex === workLength - 1) {
					nextWorkIndex = 0;
				} else {
					nextWorkIndex = activeIndex + 1;
				}

				mat.uniforms.nextImage.value = sliderImages[nextWorkIndex];
				mat.uniforms.nextImage.needsUpdate = true;

				TweenLite.to(mat.uniforms.dispFactor, 1, {
					value: 1,
					ease: 'Expo.easeInOut',
					onComplete: function() {
						mat.uniforms.currentImage.value =
							sliderImages[nextWorkIndex];
						mat.uniforms.currentImage.needsUpdate = true;
						mat.uniforms.dispFactor.value = 0.0;
						isAnimating = false;
					}
				});
				return (activeIndex = nextWorkIndex);
			}
		});

		upButton.addEventListener('click', function() {
			if (!isAnimating) {
				isAnimating = true;

				if (activeIndex === 0) {
					nextWorkIndex = workLength - 1;
				} else {
					nextWorkIndex = activeIndex - 1;
				}

				mat.uniforms.nextImage.value = sliderImages[nextWorkIndex];
				mat.uniforms.nextImage.needsUpdate = true;

				TweenLite.to(mat.uniforms.dispFactor, 1, {
					value: 1,
					ease: 'Expo.easeInOut',
					onComplete: function() {
						mat.uniforms.currentImage.value =
							sliderImages[nextWorkIndex];
						mat.uniforms.currentImage.needsUpdate = true;
						mat.uniforms.dispFactor.value = 0.0;
						isAnimating = false;
					}
				});
				return (activeIndex = nextWorkIndex);
			}
		});

		mainScreen.addEventListener('wheel', function(e) {
			if (!isAnimating) {
				isAnimating = true;

				let direction = e.deltaY > 0 ? 'down' : 'up';
	
				/// Handle Up
				if (direction === 'up') {
					// Check if active is index 0
					if (activeIndex === 0) {
						nextWorkIndex = workLength - 1;
					} else {
						nextWorkIndex = activeIndex - 1;
					}
				}
	
				/// Handle Down
				if (direction === 'down') {
					if (activeIndex === workLength - 1) {
						nextWorkIndex = 0;
					} else {
						nextWorkIndex = activeIndex + 1;
					}
				}
				
				mat.uniforms.nextImage.value = sliderImages[nextWorkIndex];
				mat.uniforms.nextImage.needsUpdate = true;

				TweenLite.to(mat.uniforms.dispFactor, 1, {
					value: 1,
					ease: 'Expo.easeInOut',
					onComplete: function() {
						mat.uniforms.currentImage.value =
							sliderImages[nextWorkIndex];
						mat.uniforms.currentImage.needsUpdate = true;
						mat.uniforms.dispFactor.value = 0.0;
						isAnimating = false;
					}
				});
				return (activeIndex = nextWorkIndex);
			}
		})
	};

	addEvents();

	window.addEventListener('resize', function(e) {
		renderer.setSize(renderW, renderH);
	});

	let animate = function() {
		requestAnimationFrame(animate);

		renderer.render(scene, camera);
	};
	animate();
};
