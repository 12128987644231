export const mobileNavToggle = toggle => dispatch => {
  dispatch({
    type: 'NAV_MOBILE_TOGGLE',
    payload: toggle
  });
};

export const updatePageLoad = (pageLoaded, logo) => dispatch => {
  dispatch({
    type: 'NAV_UPDATE_PAGE_LOAD',
    payload: { pageLoaded, logo }
  });
};

export const changeRoute = (history, route) => dispatch => {
  dispatch({
    type: 'NAV_UPDATE_PAGE_LOAD',
    payload: { pageLoaded: false, logo: false }
  });
  dispatch({
    type: 'NAV_MOBILE_TOGGLE',
    payload: false
  });
  setTimeout(() => {
    history.push(route);
  }, 1000);
};

export const logoHover = pose => dispatch => {
  dispatch({
    type: 'NAV_LOGO_HOVER',
    payload: pose
  });
};
