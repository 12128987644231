import React from 'react';
import logoSV2 from '../../../img/logoSV2.png';
import posed from 'react-pose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import * as actions from '../services/navActions';

const ImgNavButton = posed.div({
  idle: {
    y: -100,
    opacity: 0,
    transition: {
      y: { ease: 'easeInOut', duration: 1200 },
      opacity: { ease: 'easeInOut', duration: 1200 },
      default: { ease: 'easeInOut', duration: 1200 }
    }
  },
  active: {
    y: 0,
    opacity: 1,
    delay: 1200,
    transition: {
      y: { ease: 'easeInOut', duration: 1200 },
      opacity: { ease: 'easeInOut', duration: 1200 },
      default: { ease: 'easeInOut', duration: 1200 }
    }
  }
});
const PageNav = posed.div({
  unloaded: {
    opacity: 0,
    delay: 500,
    transition: {
      opacity: { ease: 'easeInOut', duration: 400 },
      default: { ease: 'easeInOut', duration: 400 }
    }
  },
  loaded: {
    opacity: 1,
    transition: {
      opacity: { ease: 'easeInOut', duration: 800 },
      default: { ease: 'easeInOut', duration: 800 }
    }
  }
});

const LogoSVPathLeft = posed.path({
  notHovered: {
    fill: '#FFF',
    x: 0,
    transition: { ease: 'linear', duration: 400 }
  },
  hovered: {
    fill: '#ffb96c',
    x: -25,
    transition: { ease: 'linear', duration: 400 }
  }
});

const LogoSVPathRight = posed.path({
  notHovered: {
    fill: '#FFF',
    x: 0,
    transition: { ease: 'linear', duration: 400 }
  },
  hovered: {
    fill: '#ffb96c',
    x: 25,
    transition: { ease: 'linear', duration: 400 }
  }
});

const ArrowPrev = posed.div({
  workNavIdle: {
    opacity: 0,
    x: -150,
    y: '-50%',
    transition: { ease: 'easeInOut', duration: 1000 }
  },
  workNavActive: {
    opacity: 1,
    x: 0,
    y: '-50%',
    delay: 1500,
    transition: { ease: 'easeInOut', duration: 1000 }
  }
});

const ArrowNext = posed.div({
  workNavIdle: {
    opacity: 0,
    x: 150,
    y: '-50%',
    transition: { ease: 'easeInOut', duration: 1000 }
  },
  workNavActive: {
    opacity: 1,
    x: 0,
    y: '-50%',
    delay: 1500,
    transition: { ease: 'easeInOut', duration: 1000 }
  }
});

class MobileNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth
    };

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillMount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  render() {
    return (
      <PageNav
        className="mobilenavbar"
        pose={this.props.pageLoaded ? 'loaded' : 'unloaded'}
      >
        <div
          className={
            this.props.mobileToggle && this.props.pageLoaded
              ? 'mobilenav fixed-top show-menu'
              : 'mobilenav fixed-top'
          }
        >
          <div className="menu-wrap">
            <nav className="menu">
              <div className="icon-list">
                <ul>
                  <li>
                    <div
                      onClick={() =>
                        this.props.changeRoute(this.props.history, '/')
                      }
                      className={
                        this.props.history.location.pathname === '/'
                          ? 'activeNavColor'
                          : null
                      }
                    >
                      <span>Home</span>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() =>
                        this.props.changeRoute(this.props.history, '/about')
                      }
                      className={
                        this.props.history.location.pathname === '/about'
                          ? 'activeNavColor'
                          : null
                      }
                    >
                      <span>About</span>
                    </div>
                  </li>
                  <li>
                    <div
                      className={
                        this.props.history.location.pathname === '/works'
                          ? 'activeNavColor'
                          : null
                      }
                      onClick={() =>
                        this.props.changeRoute(this.props.history, '/works')
                      }
                    >
                      <span>Works</span>
                    </div>
                  </li>
                </ul>
                <div className="social-links">
                  <ul>
                    <li>
                      <a
                        href="https://github.com/DzTheRage"
                        className="social"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          className="fa fa-github moveright"
                          aria-hidden="true"
                        />
                        Github
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/rsv-jr/"
                        className="social"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          className="fa fa-linkedin moveright"
                          aria-hidden="true"
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                        Linkedin
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/woogledoogle/"
                        className="social"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          className="fa fa-instagram moveright"
                          aria-hidden="true"
                        />
                        Instagram
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="social-links">
                  <div className="flex-center">
                    <img
                      className="flex-center"
                      src={logoSV2}
                      width="80px"
                      height="42px"
                      alt="logo"
                    />
                  </div>
                </div>
              </div>
            </nav>
            <button
              className="close-button"
              id="close-button"
              onClick={() => {
                this.props.mobileNavToggle(false);
              }}
            >
              Close Menu
            </button>
          </div>
          <button
            className="menu-button"
            id="open-button"
            onClick={() => {
              this.props.mobileNavToggle(true);
            }}
          >
            Open Menu
          </button>
        </div>
        <nav className="navbar navbar-dark fixed-top">
          <div className="d-flex flex-row justify-content-center">
            <div className="d-flex flex-row justify-content-center">
              {this.props.history.location.pathname.includes('/works/') &&
              this.state.windowWidth < 751 ? (
                <div className="nav-fillpath mobileWorkyNav">
                  <ArrowPrev
                    style={{ cursor: 'pointer' }}
                    className="prev testmove2"
                    onClick={() => {
                      switch (this.props.history.location.pathname) {
                        case '/works/grimdawn':
                          this.props.changeRoute(
                            this.props.history,
                            '/works/gd-ux'
                          );
                          break;

                        case '/works/gd-ux':
                          this.props.changeRoute(
                            this.props.history,
                            '/works/trusouq'
                          );
                          break;

                        case '/works/trusouq':
                          this.props.changeRoute(
                            this.props.history,
                            '/works/hvgp'
                          );
                          break;

                        case '/works/hvgp':
                          this.props.changeRoute(
                            this.props.history,
                            '/works/gofergift'
                          );
                          break;

                        case '/works/gofergift':
                          this.props.changeRoute(
                            this.props.history,
                            '/works/grimdawn'
                          );
                          break;

                        default:
                      }
                    }}
                  >
                    <span className="icon-wrap" />
                  </ArrowPrev>
                </div>
              ) : (
                <div className="p-2" />
              )}
              <div className="p-2">
                <ImgNavButton pose={this.props.pageLoaded ? 'active' : 'idle'}>
                  <div
                    className="navlogoSVsvgDesktop"
                    onClick={() =>
                      this.props.history.location.pathname === '/'
                        ? null
                        : this.props.changeRoute(this.props.history, '/')
                    }
                    onMouseEnter={() => this.props.logoHover(true)}
                    onMouseLeave={() => this.props.logoHover(false)}
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="68px"
                      height="50px"
                      viewBox="0 0 520.5 381"
                      style={{
                        enableBackground: 'new 0 0 520.5 381'
                      }}
                      space="preserve"
                      className="logoSVG"
                    >
                      <g>
                        <defs>
                          <rect
                            id="SVGID_1_"
                            x="-41.1"
                            y="-202.1"
                            width="612"
                            height="792"
                          />
                        </defs>
                        <clipPath id="SVGID_2_">
                          <use
                            href="#SVGID_1_"
                            style={{ overflow: 'visible' }}
                          />
                        </clipPath>
                        <LogoSVPathRight
                          pose={
                            this.props.logoHoverValue === true
                              ? 'hovered'
                              : 'notHovered'
                          }
                          d="M468.9,118.6l-80.7,254.3H145.6l-24.3-62.7h188c0,0,26.2,0.2,33.3-18.7l15.4-51c20.3-62.3,22.7-67.2-34-67.7
		c-15.1-0.1-30.7,0.1-39.3,0.1c-17.9-0.1-22.2,0.1-20.4-10.1l10.7-35c0,0,1.3-9.3,17.7-9.3"
                        />
                        <LogoSVPathLeft
                          pose={
                            this.props.logoHoverValue === true
                              ? 'hovered'
                              : 'notHovered'
                          }
                          d="M275.6,267.9c28.7,0,28-1.1,35.8-28.5c8-28,9.5-29.1-12-29.5c-10.5-0.2-26.2,0-26.2,0c-52,0-64.8,1.5-58.2-24
		c2.7-10.4,6.6-25.7,12.2-45.6c4-14.2,8.1-29.4,11.4-38c11.9-31.5,33.2-26.5,104.6-26.3c92.3,0.2,139.3,0,139.3,0l20.3-62h-314
		l-41.6,149c0,0-2.5,10.7-6.4-0.2l-53-148.8h-69l89.7,254H275.6"
                        />
                      </g>
                    </svg>
                  </div>
                </ImgNavButton>
              </div>
              {this.props.history.location.pathname.includes('/works/') &&
              this.state.windowWidth < 751 ? (
                <div class="nav-fillpath mobileWorkyNav">
                  <ArrowNext
                    style={{ cursor: 'pointer' }}
                    class="next testmove2"
                    onClick={() => {
                      switch (this.props.history.location.pathname) {
                        case '/works/grimdawn':
                          this.props.changeRoute(
                            this.props.history,
                            '/works/gofergift'
                          );
                          break;

                        case '/works/gd-ux':
                          this.props.changeRoute(
                            this.props.history,
                            '/works/grimdawn'
                          );
                          break;

                        case '/works/trusouq':
                          this.props.changeRoute(
                            this.props.history,
                            '/works/gd-ux'
                          );
                          break;

                        case '/works/hvgp':
                          this.props.changeRoute(
                            this.props.history,
                            '/works/trusouq'
                          );
                          break;

                        case '/works/gofergift':
                          this.props.changeRoute(
                            this.props.history,
                            '/works/hvgp'
                          );
                          break;

                        default:
                      }
                    }}
                  >
                    <span class="icon-wrap" />
                  </ArrowNext>
                </div>
              ) : (
                <div className="p-2" />
              )}
            </div>
          </div>
        </nav>
      </PageNav>
    );
  }
}

const mapStateToProps = state => {
  return {
    mobileToggle: state.nav.mobileNav.toggle,
    pageLoaded: state.nav.pageLoaded,
    logo: state.nav.logo,
    logoHoverValue: state.nav.logoHoverValue
  };
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(MobileNav));
