import update from 'immutability-helper';

/* Grim Images */
import GrimDawnImg1 from '../../../../img/grimdawnIMG1.jpg';
import GrimDawnImg2 from '../../../../img/grimdawnIMG2.jpg';
import GrimDawnImg3 from '../../../../img/grimdawnIMG3.jpg';
import GrimDawnImg4 from '../../../../img/grimdawnIMG4.jpg';
import GrimDawnImg5 from '../../../../img/grimdawnIMG5.jpg';
import GrimDawnImg6 from '../../../../img/grimdawnIMG6.jpg';
import GrimDawnImg7 from '../../../../img/grimdawnIMG7.jpg';
import GrimDawnImg8 from '../../../../img/grimdawnIMG8.jpg';
import GrimDawnImg9 from '../../../../img/grimdawnIMG9.jpg';
import GrimDawnImg10 from '../../../../img/grimdawnIMG10.jpg';
import GrimDawnImg11 from '../../../../img/grimdawnIMG11.jpg';

/*GoferGift Images */
import GoferGiftImg1 from '../../../../img/caseGoferImg1.JPG';
import GoferGiftImg2 from '../../../../img/caseGoferImg2.JPG';
import GoferGiftImg3 from '../../../../img/caseGoferImg3.JPG';
import GoferGiftImg4 from '../../../../img/caseGoferImg4.JPG';
import GoferGiftImg5 from '../../../../img/caseGoferImg5.JPG';
import GoferGiftImg6 from '../../../../img/caseGoferImg6.JPG';
import GoferGiftImg7 from '../../../../img/caseGoferImg7.JPG';
import GoferGiftImg8 from '../../../../img/caseGoferImg8.JPG';

/* HVGP Images */
import HVGPImg1 from '../../../../img/casehsv1.jpg';
import HVGPImg2 from '../../../../img/casehsv2.jpg';
import HVGPImg3 from '../../../../img/casehsv3.jpg';
import HVGPImg4 from '../../../../img/casehsv4.jpg';
import HVGPImg5 from '../../../../img/casehsv5.jpg';
import HVGPImg6 from '../../../../img/casehsv6.jpg';
import HVGPImg7 from '../../../../img/casehsv7.jpg';
import HVGPImg8 from '../../../../img/casehsv8.jpg';
import HVGPImg9 from '../../../../img/casehsv9.jpg';
import HVGPImg10 from '../../../../img/casehsv10.jpg';
import HVGPImg11 from '../../../../img/casehsv11.jpg';

/* TruSouq Images */
import TruSouqImg1 from '../../../../img/casetrusouq1.jpg';
import TruSouqImg2 from '../../../../img/casetrusouq2.jpg';
import TruSouqImg3 from '../../../../img/casetrusouq3.jpg';
import TruSouqImg4 from '../../../../img/casetrusouq4.jpg';
import TruSouqImg5 from '../../../../img/casetrusouq5.jpg';
import TruSouqImg6 from '../../../../img/casetrusouq6.jpg';
import TruSouqImg7 from '../../../../img/casetrusouq7.jpg';
import TruSouqImg8 from '../../../../img/casetrusouq8.jpg';
import TruSouqImg9 from '../../../../img/casetrusouq9.jpg';

/* GD UX Images */
import GDUXImg1 from '../../../../img/empire1-3.jpg';
import GDUXImg2 from '../../../../img/repubdog.jpg';
import GDUXImg3 from '../../../../img/beyondboards.jpg';
import GDUXImg4 from '../../../../img/hvgplogo.jpg';
import GDUXImg5 from '../../../../img/trusouqlogo.jpg';
import GDUXImg6 from '../../../../img/weicon.jpg';
import GDUXImg7 from '../../../../img/transporterframe.jpg';
import GDUXImg8 from '../../../../img/ollicatalog.jpg';
import GDUXImg9 from '../../../../img/testingbrochure.jpg';
import GDUXImg10 from '../../../../img/cocparty.jpg';
import GDUXImg11 from '../../../../img/f3.jpg';
import GDUXImg12 from '../../../../img/f1.jpg';
import GDUXImg13 from '../../../../img/f2.jpg';
import GDUXImg14 from '../../../../img/dragonlol.jpg';
import GDUXImg15 from '../../../../img/figma_wine_app.jpg';
import GDUXImg16 from '../../../../img/figma_pose_logo.jpg';

export default (
  state = {
    pageLoaed: false,
    logo: false,
    lightBoxOpen: false,
    caseStudies: [
      {
        caseName: 'grim-dawn',
        activeLightBoxIndex: 0,
        lightBoxes: [
          {
            photoIndex: 0,
            images: [GrimDawnImg1, GrimDawnImg2, GrimDawnImg3, GrimDawnImg4]
          },
          {
            photoIndex: 0,
            images: [GrimDawnImg5, GrimDawnImg7, GrimDawnImg6, GrimDawnImg8]
          },
          {
            photoIndex: 0,
            images: [GrimDawnImg10, GrimDawnImg9, GrimDawnImg11]
          }
        ]
      },
      {
        caseName: 'gofergift',
        activeLightBoxIndex: 0,
        lightBoxes: [
          {
            photoIndex: 0,
            images: [GoferGiftImg4, GoferGiftImg1, GoferGiftImg2, GoferGiftImg3]
          },
          {
            photoIndex: 0,
            images: [GoferGiftImg5, GoferGiftImg6, GoferGiftImg7, GoferGiftImg8]
          }
        ]
      },
      {
        caseName: 'hvgp',
        activeLightBoxIndex: 0,
        lightBoxes: [
          {
            photoIndex: 0,
            images: [HVGPImg1, HVGPImg2, HVGPImg3, HVGPImg4]
          },
          {
            photoIndex: 0,
            images: [HVGPImg5, HVGPImg6]
          },
          {
            photoIndex: 0,
            images: [HVGPImg9, HVGPImg8, HVGPImg7]
          },
          {
            photoIndex: 0,
            images: [HVGPImg10, HVGPImg11]
          }
        ]
      },
      {
        caseName: 'trusouq',
        activeLightBoxIndex: 0,
        lightBoxes: [
          {
            photoIndex: 0,
            images: [TruSouqImg1, TruSouqImg2, TruSouqImg4, TruSouqImg3]
          },
          {
            photoIndex: 0,
            images: [TruSouqImg5, TruSouqImg6]
          },
          {
            photoIndex: 0,
            images: [TruSouqImg7, TruSouqImg8, TruSouqImg9]
          }
        ]
      },
      {
        caseName: 'gd-ux',
        activeLightBoxIndex: 0,
        lightBoxes: [
          {
            photoIndex: 0,
            images: [GDUXImg1, GDUXImg2, GDUXImg3, GDUXImg4, GDUXImg5, GDUXImg6]
          },
          {
            photoIndex: 0,
            images: [GDUXImg7, GDUXImg8, GDUXImg9, GDUXImg10]
          },
          {
            photoIndex: 0,
            images: [GDUXImg11, GDUXImg12, GDUXImg13, GDUXImg14]
          },
          {
            photoIndex: 0,
            images: [GDUXImg15, GDUXImg16]
          }
        ]
      }
    ]
  },
  action
) => {
  switch (action.type) {
    case 'WORKS_CASE_LIGHTBOX':
      state = update(state, {
        lightBoxOpen: {
          $set: action.payload.toggle
        },
        caseStudies: {
          [action.payload.caseStudyIndex]: {
            activeLightBoxIndex: {
              $set: action.payload.lightBoxIndex
            },
            lightBoxes: {
              [action.payload.lightBoxIndex]: {
                photoIndex: {
                  $set: action.payload.photoIndex
                }
              }
            }
          }
        }
      });
      break;
    default:
  }
  return state;
};
