import posed from 'react-pose';

export const PageBackGround = posed.div({});
export const PageContainer = posed.div({
  unloaded: {
    opacity: 0,
    transition: {
      opacity: { ease: 'easeInOut', duration: 400 },
      default: { ease: 'easeInOut', duration: 400 }
    }
  },
  loaded: {
    opacity: 1,
    delay: 700,
    transition: {
      opacity: { ease: 'easeInOut', duration: 1200 },
      default: { ease: 'easeInOut', duration: 1200 }
    }
  }
});

/* WORK ITEMS */
export const WorkItem = posed.div({
  active: {
    opacity: 1,
    transition: {
      opacity: { ease: 'easeInOut', duration: 300 },
      default: { ease: 'easeInOut', duration: 300 }
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      opacity: { ease: 'easeInOut', duration: 300 },
      default: { ease: 'easeInOut', duration: 300 }
    }
  }
});

export const WorkContentGroup = posed.div({
  groupIdle: {
    opacity: 0,
    x: 60
  },
  groupActive: {
    opacity: 1,
    x: 0,
    transition: {
      y: { ease: 'linear', duration: 400 },
      opacity: { ease: 'linear', duration: 1000 },
      default: { ease: 'linear', duration: 200 }
    },
    delayChildren: 400,
    staggerChildren: 200
  }
});

export const WorkContentItem = posed.div({
  groupIdle: {
    opacity: 0,
    x: 100
  },
  groupActive: {
    x: 0,
    delay: 400,
    opacity: 1,
    transition: {
      x: { ease: 'easeInOut', duration: 600 },
      opacity: { ease: 'easeInOut', duration: 600 },
      default: { ease: 'easeInOut', duration: 800 }
    }
  }
});
