import posed from 'react-pose';

/* Page Load & Exit */
export const PageBackGround = posed.div({
  unloaded: {
    backgroundColor: '#000000',
    transition: {
      backgroundColor: { ease: 'easeInOut', duration: 1000 }
    }
  },
  loaded: {
    backgroundColor: '#171717',
    delay: 100,
    transition: {
      backgroundColor: { ease: 'easeInOut', duration: 1000 }
    }
  }
});
export const PageContainer = posed.div({
  unloaded: {
    opacity: 0,
    transition: {
      opacity: { ease: 'easeInOut', duration: 400 },
      default: { ease: 'easeInOut', duration: 400 }
    }
  },
  loaded: {
    opacity: 1,
    delay: 700,
    transition: {
      opacity: { ease: 'easeInOut', duration: 1200 },
      default: { ease: 'easeInOut', duration: 1200 }
    }
  }
});
