import { createStore, combineReducers, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import HomeReducer from './scenes/Home/services/HomeReducer';
import WorksPreviewReducer from './scenes/Works/services/WorksPreviewReducer';
import CaseStudiesReducer from './scenes/Works/scenes/services/WorkCaseReducer';
import NavReducer from './components/nav/services/navReducer';
import reduxThunk from 'redux-thunk';

export default createStore(
  combineReducers({
    home: HomeReducer,
    works: combineReducers({
      workPreview: WorksPreviewReducer,
      caseStudies: CaseStudiesReducer
    }),
    nav: NavReducer
  }),
  applyMiddleware(reduxThunk, logger)
);
