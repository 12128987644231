import React from 'react';
import { connect } from 'react-redux';

import divider2 from '../../img/divider2.png';
import HTMLBUTTON from '../../img/HTMLBUTTON.png';
import ADOBEBUTTON from '../../img/ADOBEBUTTON.png';
import BACKENDBUTTON from '../../img/BACKENDBUTTON.png';
import CMSBUTTON from '../../img/CMSBUTTON.png';
import CSSBUTTON from '../../img/CSSBUTTON.png';
import FRAMEBUTTON from '../../img/FRAMEBUTTON.png';
import JAVABUTTON from '../../img/JAVABUTTON.png';

import SnameSVG from './components/SnameSVG';

import MobileSkillGroup from './components/mobileSkillGroup';
import * as poseComponents from './components/poseComponents';

import * as actions from '../../components/nav/services/navActions';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SVnameAnimation: false,
      htmlButtonPose: 'idle',
      adobeButtonPose: 'idle',
      backendButtonPose: 'idle',
      cmsButtonPose: 'idle',
      cssButtonPose: 'idle',
      frameButtonPose: 'idle',
      javaButtonPose: 'idle',
      ///
      activeSkillInfoGroup: null,
      skillsInfoContainer: 'idle',
      ///
      mobileSkills: [
        {
          name: 'HTML',
          proficiency: 'Expert'
        },
        {
          name: 'CSS',
          proficiency: 'Expert'
        },
        {
          name: 'Javascript',
          proficiency: 'Great'
        },
        {
          name: 'React',
          proficiency: 'Great'
        },
        {
          name: 'Redux',
          proficiency: 'Great'
        },
        {
          name: 'Photoshop',
          proficiency: 'Great'
        },
        {
          name: 'Illustrator',
          proficiency: 'Great'
        },
        {
          name: 'React Native',
          proficiency: 'Good'
        },
        {
          name: 'WordPress',
          proficiency: 'Good'
        },
        {
          name: 'AWS',
          proficiency: 'Good'
        },
        {
          name: 'InDesign',
          proficiency: 'Good'
        },
        {
          name: 'Figma',
          proficiency: 'Good'
        },
        {
          name: 'Three',
          proficiency: 'Novice'
        },
        {
          name: 'Node',
          proficiency: 'Novice'
        }
      ]
    };

    this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
    this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);
    this.handleOnMouseSNAME = this.handleOnMouseSNAME.bind(this);
  }

  componentDidMount() {
    this.props.updatePageLoad(true, true);
    this.setState({ SVnameAnimation: true });
  }

  componentWillUnmount() {
    this.props.updatePageLoad(false, false);
    this.setState({ SVnameAnimation: false });
  }

  handleOnMouseSNAME(bool) {
    this.setState({ SVnameAnimation: bool });
  }

  handleOnMouseEnter(button, pose) {
    this.setState({ [button]: pose, activeSkillInfoGroup: button });
    this.setState({ skillsInfoContainer: 'active' });
  }

  handleOnMouseLeave(button, pose) {
    this.setState({ [button]: pose });
    this.setState({ skillsInfoContainer: 'active' });
  }

  render() {
    return (
      <div>
        <poseComponents.PageBackGround
          pose={this.props.pageLoaded ? 'loaded' : 'unloaded'}
          className="aboutsection"
        >
          <div className="container">
            <poseComponents.PageContainer
              className="intro wow fadeInUp"
              data-wow-delay=".8s"
            >
              <div className="row flex-center">
                <div
                  className="col-xl-6 col-lg-5 col-md-12 col-sm-12 flex-center"
                  onMouseEnter={() => this.handleOnMouseSNAME(false)}
                  onMouseLeave={() => this.handleOnMouseSNAME(true)}
                >
                  <SnameSVG SVnameAnimation={this.state.SVnameAnimation} />
                </div>
                <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12 landingtext">
                  <h1 className="h2-responsive aboutheading">WHO AM I?</h1>
                  <h4 className="h4-responsive aboutparagraph">
                    I am Scott Vance, a web developer with a knack for
                    developing unique and memorable user experiences.{' '}
                  </h4>
                </div>
              </div>
            </poseComponents.PageContainer>

            <poseComponents.PageContainer
              className="row aboutfont flex-center wow fadeInUp"
              data-wow-delay="1.4s"
            >
              <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12 aboutdetails">
                <h1 className="h2-responsive aboutheading2">MY DRIVE</h1>
                <p className="aboutinfo flex-center">
                    Digging deep and solving problems is the name of the game
                    for me. Challenging projects are very rewarding and allow me
                    to come up with innovative solutions for problems.
                </p>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                <div className="aboutside">
                  <div className="detailhead"> Location: </div>
                  <div className="detailtag"> Huntsville </div>
                  <div className="detailhead"> Education: </div>
                  <div className="detailtag"> BA in Art </div>
                  <div className="detailhead"> Experience: </div>
                  <div className="detailtag2"> 2+ Years </div>
                </div>
              </div>
            </poseComponents.PageContainer>

            <div className="row flex-center skills-section">
              <poseComponents.PageContainer>
                <div className="col-xl-12 col-lg-12-col-md-12 col-sm-12 flex-center">
                  <img className="img-fluid" src={divider2} alt="divider" />
                </div>
                <div className="col-xl-12 col-lg-12-col-md-12 col-sm-12 flex-center">
                  <h1 className="h2-responsive aboutheading skillsheading">
                    Skills
                  </h1>
                </div>
                <div className="col-xl-7 col-lg-8 col-md-8 col-sm-12 flex-center">
                  <p className="skillsinfo wow fadeInUp flex-center">
                    The world of web design is quickly evolving and so are my
                    skills. Being a student and staying on the cutting edge of
                    technology is essential to creating jaw-dropping designs.
                  </p>
                </div>
              </poseComponents.PageContainer>

              <poseComponents.PageContainer className="col-xl-6 col-lg-6 col-md-12 col-sm-12 flex-center padtop desktopskills">
                <div className="intro-block">
                  <div className="hex-master-wrap">
                    <div className="grid-1">
                      <poseComponents.SkillHexagon
                        pose={this.state.htmlButtonPose}
                        onMouseEnter={() =>
                          this.handleOnMouseEnter('htmlButtonPose', 'active')
                        }
                        onMouseLeave={() =>
                          this.handleOnMouseLeave('htmlButtonPose', 'idle')
                        }
                        className="hex-wrap"
                      >
                        <div className="hex-init" />
                        <div className="hex-borders">
                          <div className="border-1" />
                          <div className="border-2" />
                          <div className="border-3" />
                        </div>
                        <div className="label">
                          <img src={HTMLBUTTON} alt="htmlButton" />
                        </div>
                        <div className="hexagon">
                          <div className="hex-inner-1">
                            <div className="hex-inner-2" />
                          </div>
                        </div>
                      </poseComponents.SkillHexagon>
                      <poseComponents.SkillHexagon
                        pose={this.state.cssButtonPose}
                        onMouseEnter={() =>
                          this.handleOnMouseEnter('cssButtonPose', 'active')
                        }
                        onMouseLeave={() =>
                          this.handleOnMouseLeave('cssButtonPose', 'idle')
                        }
                        className="hex-wrap"
                      >
                        <div className="hex-init" />
                        <div className="hex-borders">
                          <div className="border-1" />
                          <div className="border-2" />
                          <div className="border-3" />
                        </div>
                        <div className="label">
                          <img src={CSSBUTTON} alt="cssButton" />
                        </div>
                        <div className="hexagon">
                          <div className="hex-inner-1">
                            <div className="hex-inner-2" />
                          </div>
                        </div>
                      </poseComponents.SkillHexagon>
                    </div>
                    <div className="grid-2">
                      <poseComponents.SkillHexagon
                        pose={this.state.adobeButtonPose}
                        onMouseEnter={() =>
                          this.handleOnMouseEnter('adobeButtonPose', 'active')
                        }
                        onMouseLeave={() =>
                          this.handleOnMouseLeave('adobeButtonPose', 'idle')
                        }
                        className="hex-wrap"
                      >
                        <div className="hex-init" />
                        <div className="hex-borders">
                          <div className="border-1" />
                          <div className="border-2" />
                          <div className="border-3" />
                        </div>
                        <div className="label">
                          <img src={ADOBEBUTTON} alt="adobeButton" />
                        </div>
                        <div className="hexagon">
                          <div className="hex-inner-1">
                            <div className="hex-inner-2" />
                          </div>
                        </div>
                      </poseComponents.SkillHexagon>
                      <poseComponents.SkillHexagon
                        pose={this.state.frameButtonPose}
                        onMouseEnter={() =>
                          this.handleOnMouseEnter('frameButtonPose', 'active')
                        }
                        onMouseLeave={() =>
                          this.handleOnMouseLeave('frameButtonPose', 'idle')
                        }
                        className="hex-wrap css"
                      >
                        <div className="hex-init" />
                        <div className="hex-borders">
                          <div className="border-1" />
                          <div className="border-2" />
                          <div className="border-3" />
                        </div>
                        <div className="label">
                          <img src={FRAMEBUTTON} alt="frameButton" />
                        </div>
                        <div className="hexagon">
                          <div className="hex-inner-1">
                            <div className="hex-inner-2" />
                          </div>
                        </div>
                      </poseComponents.SkillHexagon>
                      <poseComponents.SkillHexagon
                        pose={this.state.javaButtonPose}
                        onMouseEnter={() =>
                          this.handleOnMouseEnter('javaButtonPose', 'active')
                        }
                        onMouseLeave={() =>
                          this.handleOnMouseLeave('javaButtonPose', 'idle')
                        }
                        className="hex-wrap"
                      >
                        <div className="hex-init" />
                        <div className="hex-borders">
                          <div className="border-1" />
                          <div className="border-2" />
                          <div className="border-3" />
                        </div>
                        <div className="label">
                          <img src={JAVABUTTON} alt="javaButton" />
                        </div>
                        <div className="hexagon">
                          <div className="hex-inner-1">
                            <div className="hex-inner-2" />
                          </div>
                        </div>
                      </poseComponents.SkillHexagon>
                    </div>
                    <div className="grid-3">
                      <poseComponents.SkillHexagon
                        pose={this.state.backendButtonPose}
                        onMouseEnter={() =>
                          this.handleOnMouseEnter('backendButtonPose', 'active')
                        }
                        onMouseLeave={() =>
                          this.handleOnMouseLeave('backendButtonPose', 'idle')
                        }
                        className="hex-wrap"
                      >
                        <div className="hex-init" />
                        <div className="hex-borders">
                          <div className="border-1" />
                          <div className="border-2" />
                          <div className="border-3" />
                        </div>
                        <div className="label">
                          <img src={BACKENDBUTTON} alt="backendButton" />
                        </div>
                        <div className="hexagon">
                          <div className="hex-inner-1">
                            <div className="hex-inner-2" />
                          </div>
                        </div>
                      </poseComponents.SkillHexagon>
                      <poseComponents.SkillHexagon
                        pose={this.state.cmsButtonPose}
                        onMouseEnter={() =>
                          this.handleOnMouseEnter('cmsButtonPose', 'active')
                        }
                        onMouseLeave={() =>
                          this.handleOnMouseLeave('cmsButtonPose', 'idle')
                        }
                        className="hex-wrap"
                      >
                        <div className="hex-init" />
                        <div className="hex-borders">
                          <div className="border-1" />
                          <div className="border-2" />
                          <div className="border-3" />
                        </div>
                        <div className="label">
                          <img src={CMSBUTTON} alt="cmsButton" />
                        </div>
                        <div className="hexagon">
                          <div className="hex-inner-1">
                            <div className="hex-inner-2" />
                          </div>
                        </div>
                      </poseComponents.SkillHexagon>
                    </div>
                  </div>
                </div>
              </poseComponents.PageContainer>
              <poseComponents.PageContainer
                className="col-xl-6 col-lg-4 col-md-8 col-sm-12 flex-center desktopskills"
                style={{ marginTop: 'auto', marginBottom: 'auto' }}
              >
                <div className="code-display">
                  <div className="code-description">
                    <poseComponents.SkillInfoGroup
                      pose={
                        this.state.activeSkillInfoGroup === 'htmlButtonPose'
                          ? 'groupActive'
                          : 'groupIdle'
                      }
                      className="html-desc desc-active"
                      style={
                        this.state.activeSkillInfoGroup === 'htmlButtonPose'
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <poseComponents.SkillInfoGroupItem
                        className="code-title"
                        style={{ color: '#E34C26' }}
                      >
                        HTML5
                      </poseComponents.SkillInfoGroupItem>
                      <poseComponents.SkillInfoGroupItem>
                        <span style={{ color: '#E34C26' }}>Experience:</span> 3
                        Years
                      </poseComponents.SkillInfoGroupItem>
                      <poseComponents.SkillInfoGroupItem>
                        <span style={{ color: '#E34C26' }}>Knowledge:</span>{' '}
                        Structure, Best Practices
                      </poseComponents.SkillInfoGroupItem>
                    </poseComponents.SkillInfoGroup>
                    <poseComponents.SkillInfoGroup
                      pose={
                        this.state.activeSkillInfoGroup === 'cssButtonPose'
                          ? 'groupActive'
                          : 'groupIdle'
                      }
                      className="css-desc desc-active"
                      style={
                        this.state.activeSkillInfoGroup === 'cssButtonPose'
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <poseComponents.SkillInfoGroupItem
                        className="code-title"
                        style={{ color: '#0083de' }}
                      >
                        Css3
                      </poseComponents.SkillInfoGroupItem>
                      <poseComponents.SkillInfoGroupItem>
                        <span style={{ color: '#0083de' }}>Experience:</span> 3
                        Years
                      </poseComponents.SkillInfoGroupItem>
                      <poseComponents.SkillInfoGroupItem>
                        <span style={{ color: '#0083de' }}>Knowledge:</span>{' '}
                        Rules, Selectors, Animation
                      </poseComponents.SkillInfoGroupItem>
                    </poseComponents.SkillInfoGroup>
                    <poseComponents.SkillInfoGroup
                      pose={
                        this.state.activeSkillInfoGroup === 'adobeButtonPose'
                          ? 'groupActive'
                          : 'groupIdle'
                      }
                      className="adobe-desc desc-active"
                      style={
                        this.state.activeSkillInfoGroup === 'adobeButtonPose'
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <poseComponents.SkillInfoGroupItem
                        className="code-title"
                        style={{ color: '#9d001d' }}
                      >
                        Adobe
                      </poseComponents.SkillInfoGroupItem>
                      <poseComponents.SkillInfoGroupItem>
                        <span style={{ color: '#9d001d' }}>Experience:</span> 5
                        Years
                      </poseComponents.SkillInfoGroupItem>
                      <poseComponents.SkillInfoGroupItem>
                        <span style={{ color: '#9d001d' }}>Tools:</span>{' '}
                        Photoshop, Illustrator, InDesign
                      </poseComponents.SkillInfoGroupItem>
                    </poseComponents.SkillInfoGroup>
                    <poseComponents.SkillInfoGroup
                      pose={
                        this.state.activeSkillInfoGroup === 'frameButtonPose'
                          ? 'groupActive'
                          : 'groupIdle'
                      }
                      className="framework-desc desc-active"
                      style={
                        this.state.activeSkillInfoGroup === 'frameButtonPose'
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <poseComponents.SkillInfoGroupItem
                        className="code-title"
                        style={{ color: '#919085' }}
                      >
                        Frameworks
                      </poseComponents.SkillInfoGroupItem>
                      <poseComponents.SkillInfoGroupItem>
                        <span style={{ color: '#919085' }}>Experience:</span> 2
                        Years
                      </poseComponents.SkillInfoGroupItem>
                      <poseComponents.SkillInfoGroupItem>
                        <span style={{ color: '#919085' }}>Tools:</span>{' '}
                        Bootstrap, Semantic-UI, Material UI
                      </poseComponents.SkillInfoGroupItem>
                      <div className="code-icon" />
                    </poseComponents.SkillInfoGroup>
                    <poseComponents.SkillInfoGroup
                      className="javascript-desc desc-active"
                      pose={
                        this.state.activeSkillInfoGroup === 'javaButtonPose'
                          ? 'groupActive'
                          : 'groupIdle'
                      }
                      style={
                        this.state.activeSkillInfoGroup === 'javaButtonPose'
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <poseComponents.SkillInfoGroupItem
                        className="code-title"
                        style={{ color: '#8cc84b' }}
                      >
                        Javascript
                      </poseComponents.SkillInfoGroupItem>
                      <poseComponents.SkillInfoGroupItem>
                        <span style={{ color: '#8cc84b' }}>Experience:</span> 2
                        Years
                      </poseComponents.SkillInfoGroupItem>
                      <poseComponents.SkillInfoGroupItem>
                        <span style={{ color: '#8cc84b' }}>Tools:</span> React,
                        Redux, Three, Node
                      </poseComponents.SkillInfoGroupItem>
                    </poseComponents.SkillInfoGroup>
                    <poseComponents.SkillInfoGroup
                      pose={
                        this.state.activeSkillInfoGroup === 'backendButtonPose'
                          ? 'groupActive'
                          : 'groupIdle'
                      }
                      className="backend-desc desc-active"
                      style={
                        this.state.activeSkillInfoGroup === 'backendButtonPose'
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <poseComponents.SkillInfoGroupItem
                        className="code-title"
                        style={{ color: '#89588c' }}
                      >
                        Mobile
                      </poseComponents.SkillInfoGroupItem>
                      <poseComponents.SkillInfoGroupItem>
                        <span style={{ color: '#89588c' }}>Experience:</span> 1
                        Year
                      </poseComponents.SkillInfoGroupItem>
                      <poseComponents.SkillInfoGroupItem>
                        <span style={{ color: '#89588c' }}>Tools:</span> React Native
                      </poseComponents.SkillInfoGroupItem>
                    </poseComponents.SkillInfoGroup>
                    <poseComponents.SkillInfoGroup
                      pose={
                        this.state.activeSkillInfoGroup === 'cmsButtonPose'
                          ? 'groupActive'
                          : 'groupIdle'
                      }
                      className={'cms-desc desc-active'}
                      style={
                        this.state.activeSkillInfoGroup === 'cmsButtonPose'
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <poseComponents.SkillInfoGroupItem
                        className="code-title"
                        style={{ color: '#C0392B' }}
                      >
                        CMS
                      </poseComponents.SkillInfoGroupItem>
                      <poseComponents.SkillInfoGroupItem>
                        <span style={{ color: '#C0392B' }}>Experience:</span> 2
                        Years
                      </poseComponents.SkillInfoGroupItem>
                      <poseComponents.SkillInfoGroupItem>
                        <span style={{ color: '#C0392B' }}>Tools:</span>{' '}
                        WordPress, CouchCMS
                      </poseComponents.SkillInfoGroupItem>
                    </poseComponents.SkillInfoGroup>
                  </div>
                </div>
              </poseComponents.PageContainer>
              <poseComponents.PageContainer>
                {/* Mobile Skills */}
                <MobileSkillGroup mobileSkills={this.state.mobileSkills} />
              </poseComponents.PageContainer>
            </div>
          </div>

          <poseComponents.PageContainer className="footerbg">
            <div className="container aboutfooter">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <h4 className="thanks">Thanks for visiting!</h4>
                  <p className="footerhead">
                    Get in touch:
                    <a href="mailto:scottvance1234@gmail.com">
                      <span className="footeremail"> scottvance1234@gmail.com</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </poseComponents.PageContainer>
        </poseComponents.PageBackGround>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pageLoaded: state.nav.pageLoaded
  };
};

export default connect(
  mapStateToProps,
  actions
)(About);
