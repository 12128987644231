import posed from 'react-pose';

export const HeroLogoDiv = posed.div({
  hidden: {
    opacity: 0,
    y: -300,
    transition: {
      opacity: { ease: 'linear', duration: 500 },
      y: { ease: 'easeOut', duration: 1000 },
      default: { ease: 'linear', duration: 1000 }
    }
  },
  active: {
    opacity: 1,
    y: 0,
    delay: 100,
    transition: {
      opacity: { ease: 'linear', duration: 800 },
      y: { ease: 'easeOut', duration: 800 },
      default: { ease: 'linear', duration: 1000 }
    }
  }
});

export const HeroNameDiv = posed.div({
  hidden: {
    opacity: 0,
    y: -150,
    delay: 0,
    transition: {
      opacity: { ease: 'linear', duration: 500 },
      y: { ease: 'easeOut', duration: 1000 },
      default: { ease: 'linear', duration: 1000 }
    }
  },
  active: {
    opacity: 1,
    y: 0,
    delay: 0,
    transition: {
      opacity: { ease: 'linear', duration: 1000, delay: 400 },
      y: { ease: 'easeOut', duration: 1000 },
      default: { ease: 'linear', duration: 1000 }
    }
  }
});

export const MiddleContentDiv = posed.div({
  hidden: {
    opacity: 0,
    y: 250,
    delay: 0,
    transition: {
      opacity: { ease: 'linear', duration: 500 },
      y: { ease: 'easeOut', duration: 1000 },
      default: { ease: 'linear', duration: 1000 }
    }
  },
  active: {
    opacity: 1,
    y: 0,
    delay: 300,
    transition: {
      opacity: { ease: 'linear', duration: 1000, delay: 300 },
      y: { ease: 'easeOut', duration: 1000 },
      default: { ease: 'linear', duration: 1000 }
    }
  }
});

export const ContactButtonDiv = posed.div({
  hidden: {
    opacity: 0,
    y: 170,
    delay: 0,
    transition: {
      opacity: { ease: 'linear', duration: 200 },
      y: { ease: 'easeOut', duration: 500 },
      default: { ease: 'linear', duration: 500 }
    }
  },
  active: {
    opacity: 1,
    y: 0,
    delay: 500,
    transition: {
      opacity: { ease: 'linear', duration: 1000 },
      y: { ease: 'easeOut', duration: 1000 },
      default: { ease: 'linear', duration: 1000 }
    }
  }
});

export const ContactModalOverlay = posed.div({
  active: {
    y: '0%',
    opacity: 1,
    transition: {
      y: { ease: 'easeOut', duration: 500 }
    }
  },
  hidden: {
    y: '100%',
    opacity: 0,
    transition: {
      y: { ease: 'easeOut', duration: 500 }
    }
  }
});
