import React from 'react';
import { connect } from 'react-redux';
import Lightbox from 'react-image-lightbox';

import * as navActions from '../../../components/nav/services/navActions';
import * as caseActions from './services/WorkCaseActions';
import * as poseComponents from './components/poseComponents';

//// Assets
import GoferGiftMock from '../../../img/goferGiftMock.png';

import WorkDesktopNav from './components/workDesktopNav';
import WorkCaseFooter from './components/workCaseFooter';

class TrusouqCase extends React.Component {
  componentDidMount() {
    this.props.updatePageLoad(true, true);
    console.log(this.props.caseStudy);
  }

  componentWillUnmount() {
    this.props.updatePageLoad(false, false);
  }
  render() {
    return (
      <poseComponents.PageBackGround
        pose={this.props.pageLoaded ? 'loaded' : 'unloaded'}
      >
        <poseComponents.PageContainer>
          <WorkDesktopNav {...this.props} />
          <div class="flex-center container">
            <section id="intro">
              <div id="intro-background" class="row">
                {this.props.lightBoxOpen && (
                  <Lightbox
                    mainSrc={
                      this.props.caseStudy.lightBoxes[
                        this.props.caseStudy.activeLightBoxIndex
                      ].images[
                        this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex
                      ]
                    }
                    nextSrc={
                      this.props.caseStudy.lightBoxes[
                        this.props.caseStudy.activeLightBoxIndex
                      ].images[
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      ]
                    }
                    prevSrc={
                      this.props.caseStudy.lightBoxes[
                        this.props.caseStudy.activeLightBoxIndex
                      ].images[
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length -
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      ]
                    }
                    onCloseRequest={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        false,
                        this.props.caseStudy.activeLightBoxIndex,
                        0
                      )
                    }
                    onMovePrevRequest={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        this.props.caseStudy.activeLightBoxIndex,
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length -
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      )
                    }
                    onMoveNextRequest={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        this.props.caseStudy.activeLightBoxIndex,
                        (this.props.caseStudy.lightBoxes[
                          this.props.caseStudy.activeLightBoxIndex
                        ].photoIndex +
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length +
                          1) %
                          this.props.caseStudy.lightBoxes[
                            this.props.caseStudy.activeLightBoxIndex
                          ].images.length
                      )
                    }
                  />
                )}
                <div class="col-xl-12-12 col-lg-12 col-md-12 col-sm-12">
                  <h2 class="h2-responsive caseheadersmall">
                    SAAS WORDPRESS BLOG
                  </h2>
                  <br />
                  <h2 class="h2-responsive caseheader">GOFER GIFT</h2>
                </div>
                <div class="col-xl-12-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <img
                    class="img-responsive mockimage"
                    src={GoferGiftMock}
                    alt="gofer-gift"
                  />
                </div>
              </div>
            </section>
          </div>
          <main class="main-content">
            <div class="container">
              <div class="row flex-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <div class="hexagon">
                    <p>02</p>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <h2 class="workheader">The Perfect Gift</h2>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <p class="workdescriptiontag">Logo Design</p>
                </div>
                <div class="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <p class="workdescription">
                    GoferGift is a service that helps you set reminders for
                    special events, create wishlists, and collaborate on your
                    gift giving. GoferGift needed a new logo to represent this
                    service and came to me and Cassie L. We came up with a few
                    different ideas that we thought were smart enough to portray
                    the service.
                  </p>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        1,
                        0
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[1].images[0]}
                      alt="gofer5"
                    />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        1,
                        1
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[1].images[1]}
                      alt="gofer6"
                    />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        1,
                        2
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[1].images[2]}
                      alt="gofer7"
                    />
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        1,
                        3
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[1].images[3]}
                      alt="gofer8"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <div class="hexagon">
                    <p>02</p>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <h2 class="workheader">Generating Traffic</h2>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 flex-center">
                  <p class="workdescriptiontag">
                    WordPress Blog to Promote Service
                  </p>
                </div>
                <div class="col-xl-10 col-lg-8 col-md-12 col-sm-12 flex-center">
                  <p class="workdescription">
                    GoferGift wanted to drive more users to use their service
                    while also generating another revenue stream from affiliate
                    linking. So, they came to me asked me to put up a WordPress
                    site for them in order to accomplish this. The blog contains
                    articles intended to capture common Google Searches related
                    to gift giving and drive users to buy products. I also wrote
                    some shortcodes and PHP functions that allow the article
                    writers to easily implement cleanly formatted articles and
                    SEO rich product cards. In addition, I created some
                    advertising banners to use throughout the articles.
                  </p>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        0
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[0]}
                      alt="trusouq1"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        1
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[1]}
                      alt="trusouq4"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        2
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[2]}
                      alt="trusouq2"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 flex-center">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.caseStudyLightBox(
                        this.props.caseStudyIndex,
                        true,
                        0,
                        3
                      )
                    }
                  >
                    <img
                      class="img-responsive workimages"
                      src={this.props.caseStudy.lightBoxes[0].images[3]}
                      alt="trusouq3"
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-center">
                <div class="col-xl-10 col-lg-8 col-md 12 col-sm-12 flex-center">
                  <a
                    class="projectlink"
                    href="https://gofergift.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    VISIT THIS PROJECT
                  </a>
                </div>
              </div>
            </div>
            <WorkCaseFooter />
          </main>
        </poseComponents.PageContainer>
      </poseComponents.PageBackGround>
    );
  }
}

const mapStateToProps = state => {
  return {
    logo: state.nav.logo,
    pageLoaded: state.nav.pageLoaded,
    caseStudy: state.works.caseStudies.caseStudies[1],
    caseStudyIndex: state.works.caseStudies.caseStudies.findIndex(
      caseStudy => caseStudy.caseName === 'gofergift'
    ),
    lightBoxOpen: state.works.caseStudies.lightBoxOpen
  };
};

export default connect(
  mapStateToProps,
  { ...navActions, ...caseActions }
)(TrusouqCase);
