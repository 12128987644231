export const updateColumn = (column, value) => dispatch => {
  dispatch({
    type: 'HOME_UPDATE_COLUMN',
    payload: { column, value }
  });
};

export const changeRoute = (history, route) => dispatch => {
  dispatch({
    type: 'HOME_UPDATE_COLUMN',
    payload: { column: 'loaded', value: false }
  });
  setTimeout(() => {
    history.push(route);
  }, 1000);
};
